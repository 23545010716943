export const cleanData = (value) => {
  if (value === undefined || value === null) {
    return "";
  }
  return value;
};

export function removeExtraPlus(inputString) {
  let newStr = "+" + inputString;
  return newStr.replace(/\++/g, "+");
}

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

export const b64toBlob = (cropData, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  var byteCharacters = window.atob(cropData); //decode string
  var byteArrays = [];
  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);
    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getRandom = () => {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
};
