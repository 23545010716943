import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_ID_KEY,
    uint32.toString(32)
  );
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN_KEY)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(
    process.env.REACT_APP_DEVICE_TOKEN_KEY,
    uint32.toString(32)
  );
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class TrainzaApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    accept: "application/json",
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    "device-token": localStorage.getItem(
      process.env.REACT_APP_DEVICE_TOKEN_KEY
    ),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID_KEY),
    "device-type": process.env.REACT_APP_DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    const headers = { ...TrainzaApi.commonHeaders, ...header };

    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  // SignUp======
  static async signUp(data) {
    let res = await this.request("/brand/signup", data, "post");
    return res;
  }

  // Login======
  static async login(data) {
    let res = await this.request("/brand/login", data, "post");
    return res;
  }

  // Verification======
  static async verification(data) {
    let res = await this.request("/brand/verifyCodes", data, "post");

    return res;
  }

  // UpdateLoginCredential======
  static async updateLogin(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/loginDetails", data, "put", header);
    return res;
  }

  // BrandInfo======
  static async brandInfo(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/brandInfo", data, "post", header);

    return res;
  }

  // Branding======
  static async branding(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/branding", data, "post", header);

    return res;
  }

  //BrandDetail
  static async brandDetail(authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/details`, {}, "get", header);
    return res;
  }

  //GetGroup
  static async groupList(authToken, search) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/group?search=${search}`,
      {},
      "get",
      header
    );
    return res;
  }

  //GetGroupMember
  static async groupMember(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/brand/group/${id}/members`,
      {},
      "get",
      header
    );
    return res;
  }

  // AddGroup======
  static async addGroup(authToken, data) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request("/brand/group", data, "post", header);

    return res;
  }

  //DeleteGroup
  static async DeleteGroupList(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/brand/group/${id}`, {}, "delete", header);
    return res;
  }

  //Country meta data
  static async countryMetaData() {
    // let header = { "access-token": ` ${authToken}` };
    let res = await this.request(`/superAdmin/countryMeta`, {}, "get", {});
    return res;
  }

  //Country meta data
  static async stateMetaData(id) {
    // let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/superAdmin/stateMeta?countryId=${id}`,
      {},
      "get",
      {}
    );
    return res;
  }
}

export default TrainzaApi;
