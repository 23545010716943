import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-bootstrap/Modal";
import styles from "./Result.module.css";
import { FaLessThan, FaGreaterThan } from "react-icons/fa";
import { LuCalendar } from "react-icons/lu";
import { useTranslation } from "react-i18next";

function DatePicker({
  show,
  handleClose,
  setWeekday,
  setDay,
  setMonth,
  setYear,
  previousRef,
  nextRef,
}) {
  const { t } = useTranslation();
  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [value, onChange] = useState(new Date());

  const goToPreviousMonth = () => {
    const previousMonth = new Date(
      value.getFullYear(),
      value.getMonth() - 1,
      1
    );
    onChange(previousMonth);

    const nextMonthWeekday = previousMonth.toLocaleString("default", {
      weekday: "long",
    });
    const nextMonthName = previousMonth.toLocaleString("default", {
      month: "long",
    });
    const nextMonthYear = previousMonth.getFullYear();
    const nextMonthDate = previousMonth.getDate();

    setWeekday(nextMonthWeekday);
    setDay(nextMonthDate);
    setMonth(nextMonthName);
    setYear(nextMonthYear);
  };

  React.useImperativeHandle(previousRef, () => ({
    handleCallPrevious: goToPreviousMonth,
  }));

  const goToNextMonth = () => {
    const nextMonth = new Date(value.getFullYear(), value.getMonth() + 1, 1);
    onChange(nextMonth);
    const nextMonthWeekday = nextMonth.toLocaleString("default", {
      weekday: "long",
    });
    const nextMonthName = nextMonth.toLocaleString("default", {
      month: "long",
    });
    const nextMonthYear = nextMonth.getFullYear();
    const nextMonthDate = nextMonth.getDate();

    setWeekday(nextMonthWeekday);
    setDay(nextMonthDate);
    setMonth(nextMonthName);
    setYear(nextMonthYear);
  };

  React.useImperativeHandle(nextRef, () => ({
    handleCallNext: goToNextMonth,
  }));

  const handleDateClick = (date) => {
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const day = date.getDate();
    const month = date.toLocaleDateString("en-US", { month: "long" });
    const year = date.getFullYear();

    setWeekday(weekday);
    setDay(day);
    setMonth(month);
    setYear(year);

    handleClose();
  };

  const goToToday = () => {
    onChange(new Date());
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
    handleClose();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} className="dateModal">
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            onClickDay={handleDateClick}
          />

          <div className={styles.modalBtnSection}>
            <button
              onClick={goToPreviousMonth}
              className={styles.previousOrNext}
            >
              <FaLessThan
                size="20"
                height={"12px"}
                width={"12px"}
                color="#ffffff"
              />
              <LuCalendar
                size="20"
                height={"12px"}
                width={"12px"}
                color="#ffffff"
              />
            </button>

            <button
              className={styles.showDay}
              onClick={() => {
                goToToday();
              }}
            >
              {t("TODAY")}
            </button>

            <button onClick={goToNextMonth} className={styles.previousOrNext}>
              <LuCalendar
                size="20"
                height={"12px"}
                width={"12px"}
                color="#ffffff"
              />
              <FaGreaterThan
                size="20"
                height={"12px"}
                width={"12px"}
                color="#ffffff"
              />
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DatePicker;
