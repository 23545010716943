import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import styles from "./SaveChangesModal.module.css";
import { useDispatch, useSelector } from "react-redux";
import { is_modal_close } from "../../../redux/slices/userSlice";
import { useContext } from "react";
import { UserContext } from "../../../Context/UserContext";

function SaveChangesModal({}) {
  const { t } = useTranslation("");

  const dispatch = useDispatch();

  const saveModalOpen = useSelector((state) => state.user.open_modal);

  const { setIsAnyChange, setSaveChanges } = useContext(UserContext);

  return (
    <div>
      <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal
          show={saveModalOpen}
          onHide={() => {
            dispatch(is_modal_close());
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <div className={styles.modalHead}>
                <p>
                  {t("CHANGES")} <span>{t("NOT_SAVED")}</span>
                </p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.modalBody}>
              <p>{t("YOU_HAVE_NOT_SAVE_CHANGES")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.btnSection}>
              <button
                className={styles.close}
                onClick={() => {
                  dispatch(is_modal_close());
                }}
              >
                {t("CENCEL")}
              </button>
              <div style={{ display: "flex" }}>
                <button
                  className={styles.delete}
                  onClick={() => {
                    setIsAnyChange(false);
                    dispatch(is_modal_close());
                  }}
                >
                  {t("DISCARD_CHANGES")}
                </button>

                <button
                  className={styles.delete}
                  onClick={() => {
                    dispatch(is_modal_close());
                    setSaveChanges(true);
                  }}
                >
                  {t("SAVE_CHANGES")}
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}
export default SaveChangesModal;
