import { Outlet } from "react-router-dom";
import styles from "./CommonLayout.module.css";
import Sidebar from "../Sidebar/Sidebar";
import React from "react";

export default function CommonLayout() {
  return (
    <React.Fragment>
      <div className={styles.AppRoot}>
        <Sidebar />

        <div className={styles.MainPage}>
          <div className={styles.mainPageChild}>
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
