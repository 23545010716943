import React, { useEffect, useRef, useState } from "react";
import styles from "./Training.module.css";
import DatePicker from "../../Members/MemberDetail/DatePicker";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { useTranslation } from "react-i18next";
import EditImg from "../../../Assests/Images/Edit.png";
import CrossImg from "../../../Assests/Images/Cross.png";
import TrainingDateTime from "./TrainingDateTime";
import WorkoutRequired from "./WorkoutRequired";
import RouteOptional from "./RouteOptional";

function Training() {
  const { t } = useTranslation();
  const previousRef = useRef();
  const nextRef = useRef();
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const currentDate = new Date();

  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];

  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  return (
    <div>
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#000000" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#000000" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={handleShowDate}
        />
      </div>

      <div className={styles.trainingDetailBox}>
        <div className={styles.trainingInfo}>
          <h1>
            05:00 <span>- Running - Hillwork</span>
          </h1>
          <p>
            Bedfordview Garage to Garage - <span>12</span>
            {t("KM")}
          </p>
          <h2>
            A School <span className={styles.separation}>|</span> Beginner Squad
          </h2>
        </div>
        <div className={styles.trainingOption}>
          <img src={CrossImg} alt="crossimg"></img>
          <img src={EditImg} alt="EditImg"></img>
        </div>
      </div>

      <div className={styles.addWorkoutBox}>
        <p>
          2 <span>{t("WORKOUT_S")}</span>
        </p>
        <button>
          <p>
            {t("ADD")}
            <span> {t("WORKOUT")}</span>
          </p>
        </button>
      </div>

      <TrainingDateTime />
      <WorkoutRequired />
      <RouteOptional />

      <DatePicker
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
      />
    </div>
  );
}

export default Training;
