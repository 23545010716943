import React, { createContext, useState } from "react";

export const UserContext = createContext();
export const ContextProvider = ({ children }) => {
  const [isAnyChange, setIsAnyChange] = useState(false);
  const [saveChanges, setSaveChanges] = useState(false);

  return (
    <UserContext.Provider
      value={{
        isAnyChange,
        setIsAnyChange,
        saveChanges,
        setSaveChanges,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
