import React, { useCallback, useEffect, useState } from "react";
import styles from "./GroupLayout.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Groups from "./Groups";
import { FaPlus } from "react-icons/fa";
import AddGroup from "./AddGroup";
import TrainzaApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from "debounce";
function GroupLayout() {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showtab, setShowtab] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [grpList, setGrpList] = useState([]);

  const handleGetGroupList = (search) => {
    const response = TrainzaApi.groupList(isAuthenticated, search);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        setGrpList(response?.data?.groupList);
      }
    });
  };

  const handleChange = useCallback(
    debounce((event) => {
      setSearchKeyword(event);
    }, 500),
    []
  );

  useEffect(() => {
    if (showtab == 2) {
      setSearchKeyword("");
    }
  }, [showtab]);

  return (
    <div>
      {showtab === 1 ? (
        <p className={styles.heading}>{t("TRAINING_GROUPS")}</p>
      ) : (
        <p className={styles.heading}>{t("TRAINING_GROUP_ADD_GROUP")}</p>
      )}
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <button
            className={showtab === 1 ? styles.members : styles.invites}
            onClick={() => setShowtab(1)}
          >
            {t("GROUPS_")}
          </button>

          {showtab === 2 ? (
            <div className={styles.addGrp}>
              <FaPlus color="#FFFFFF" />
            </div>
          ) : (
            <img
              src={PlusIcon}
              alt="PlusIcon"
              width="35px"
              height="35px"
              onClick={() => setShowtab(2)}
            ></img>
          )}
        </div>

        {showtab !== 2 ? (
          <input
            type="text"
            className={styles.search}
            placeholder={t("SEARCH_")}
            onChange={(e) => handleChange(e.target.value)}
          ></input>
        ) : (
          ""
        )}
      </div>

      <div className={styles.devider}></div>
      {showtab === 1 ? (
        <Groups
          searchKeyword={searchKeyword}
          setSearchKeyword={setSearchKeyword}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          handleGetGroupList={handleGetGroupList}
          grpList={grpList}
          setGrpList={setGrpList}
          setShowtab={setShowtab}
        />
      ) : (
        <AddGroup setShowtab={setShowtab} />
      )}
    </div>
  );
}

export default GroupLayout;
