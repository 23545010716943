import React, { useEffect, useRef, useState } from "react";
import styles from "./Result.module.css";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import Form from "react-bootstrap/Form";
import SelectImg from "../../../Assests/Images/SelectImg.png";
import DatePicker from "./DatePicker";
import { useTranslation } from "react-i18next";

function EditResult({ setShowEdit, setEditIndex }) {
  const { t } = useTranslation();
  const previousRef = useRef();
  const nextRef = useRef();

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const handleShowDate = () => setShowDate(true);
  const handleCloseDate = () => setShowDate(false);

  const currentDate = new Date();
  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  return (
    <div>
      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#ff4300" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#ff4300" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={() => {
            handleShowDate();
          }}
        />
      </div>

      <div className={styles.fields}>
        <Form.Group className={styles.inputArea}>
          <Form.Label>
            <div className={styles.inputLabel}>
              <p>
                {t("SELECT")} <span>{t("EVENT")}</span>
              </p>
            </div>
          </Form.Label>
          <Form.Select className={styles.inputSelect}>
            <option>{t("DIS_CHEM_HALF_MARATHON_2024")}</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <img src={SelectImg} alt="SelectImg"></img>
        </Form.Group>

        <Form.Group className={styles.inputArea}>
          <Form.Label>
            <div className={styles.inputLabel}>
              <p>
                {t("SELECT")} <span>{t("DISTANCE")}</span>
              </p>
            </div>
          </Form.Label>
          <Form.Select className={styles.inputSelect}>
            <option>21.2{t("KM")}</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <img src={SelectImg} alt="SelectImg"></img>
        </Form.Group>
        <div className={styles.timeAndPace}>
          <div>
            <p className={styles.timeHeading}>{t("TIME_")}</p>
            <div className={styles.inputBox}>
              <div className={styles.inputContainer}>
                <input></input>
                <p>{t("HOURS")} </p>
              </div>
              <p
                style={{
                  color: "#6C757D",
                  margin: "0px 5px",
                  fontSize: "20px",
                }}
              >
                :
              </p>
              <div className={styles.inputContainer}>
                <input></input>
                <p>{t("MINUTES")} </p>
              </div>
              <p
                style={{
                  color: "#6C757D",
                  margin: "0px 5px",
                  fontSize: "20px",
                }}
              >
                :
              </p>
              <div className={styles.inputContainer}>
                <input></input>
                <p>{t("SECONDS")} </p>
              </div>
            </div>
          </div>
          <div className={styles.pace}>
            <p>PACE</p>
            <input></input>
          </div>
        </div>
      </div>
      <div className={styles.scheduleFooter}>
        <div className={styles.cancelOrDelete}>
          <button
            onClick={() => {
              setEditIndex();
              setShowEdit(false);
            }}
          >
            {t("CENCEL")}
          </button>
          <button>{t("DELETE")}</button>
        </div>
        <div className={styles.saveButton}>
          <button>{t("SAVE")}</button>
        </div>
      </div>

      <DatePicker
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
      />
    </div>
  );
}

export default EditResult;
