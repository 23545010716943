export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "AUTH_TOKEN_IS_REQUIRED",
  104: "INVALID_TOKEN",
  105: "VALIDATION_ERROR",
  111: "INVALID_LOGIN_CREDENTIAL",
  112: "EMAIL_EXIST",
  113: "SESSION_EXPIRED",
  114: "BRAND_NAME_EXIST",
  115: "INVALID_VERIFICATION_CODE",
  116: "FAILED_TO_SEND_EMAIL",
  117: "INVALID_BRAND_ID",
  118: "INVALID_IMAGE_SIZE",
  119: "INVALID_BRAND_FILE_EXTENSION",
  120: "INVALID_APP_LOGO_IMAGE_SIZE",
  121: "INVALID_APP_LOGO_FILE_EXTENSION",
  122: "APP_LOGO_REQUIRED",
  123: "COUNTRY_ID_IS_REQUIRED",
  124: "INVALID_GROUP_ID",
  125: "EMAIL_AND_PHONE_NUMBER_EXIST",
  126: "INVALID_MEMBER_ID",
  127: "INVALID_MEMBER_PICTURE_SIZE",
  128: "INVALID_MEMBER_FILE_EXTENSION",
  129: "GROUP_NAME_EXIST",
  130: "INVALID_PERSONAL_BESTS_OBJECT",
  131: "INVALID_BRANDS_OBJECT",
  132: "MEMBER_LIMIT_EXCEEDED",
  133: "THE_GROUP_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION",
  134: "PHONE_NUMBER_EXIST",
  135: "PROFILE_PICTURE_REQUIRED",
  136: "INVALID_EVENT_ID",
  137: "INVALID_EVENT_PICTURE_SIZE",
  138: "INVALID_EVENT_FILE_EXTENSION",
  139: "INVALID_RESULT_DISTANCE_JSON_OBJECT",
  140: "INVALID_KEY_IN_RESULT_DISTANCE_JSON_OBJECT",
  141: "EVENT_NAME_ALREADY_EXIST",
  142: "MEMBER_IS_INACTIVE",
  143: "ACCOUNT_NOT_FOUND",
  144: "INVALID_PERSONAL_BESTS_JSON_OBJECT",
  145: "INVALID_BRANDS_JSON_OBJECT",
  146: "EVENT_GALLERY_REQUIRED",
  147: "INVALID_EVENT_GALLERY_PICTURE_SIZE",
  148: "INVALID_EVENT_GALLERY_FILE_EXTENSION",
  149: "BRANDS_FIELD_IS_REQUIRED",
  150: "INVALID_POLL_ANSWERS_JSON_OBJECT",
  151: "INVALID_KEY_IN_POLL_ANSWERS_JSON_OBJECT",
  152: "DUPLICATE_POLL_ANSWER_FOUND",
  153: "NUMBER_OF_POLL_ANSWER_CAN_BE_MAXIMUM_10",
  154: "INVALID_TRAINING_GROUPS_JSON_OBJECT",
  155: "ALREADY_A_MEMBER_OF_THIS_CLUB",
  156: "MEMBER_INVITATION_IS_ALREADY_SENT",
  200: "SUCCESS",
  500: "INTERNAL_SERVER_ERROR",
};
