import React from "react";
import styles from "./MemberDetail.module.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MemberDetail from "./MemberDetail";
import Result from "./Result";

import LogBook from "./LogBook";
import { useTranslation } from "react-i18next";
function MemberDetailLayout() {
  const { t } = useTranslation();
  return (
    <div>
      <p className={styles.pageHead}>
        {t("MEMBERS")} {">"} {t("DETAILS_")}
      </p>
      <Tabs
        defaultActiveKey="Details"
        id="uncontrolled-tab-example"
        className="tabs"
      >
        <Tab eventKey="Details" title={t("DETAILS")}>
          <MemberDetail />
        </Tab>
        <Tab eventKey="Results" title={t("RESULTS_")}>
          <Result />
        </Tab>
        <Tab eventKey="Logbook" title={t("LOGBOOK")}>
          <LogBook />
        </Tab>
      </Tabs>
    </div>
  );
}

export default MemberDetailLayout;
