import React, { useEffect, useState } from "react";
import styles from "./Groups.module.css";
import CrossImg from "../../Assests/Images/Cross.png";
import EditImg from "../../Assests/Images/Edit.png";
import AddGroup from "./AddGroup";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter } from "../../Utils/CommonFunction";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Toster from "../../Common/Toster/Toster";
import { useNavigate } from "react-router-dom";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { useDispatch, useSelector } from "react-redux";
import TrainzaApi from "../../Helpers/Api";
import Loader from "../../Common/Loader/Loader";
import DeleteResult from "../Members/MemberDetail/DeleteResult";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import EditGroup from "./EditGroup";

function Groups({
  searchKeyword,
  isLoading,
  setIsLoading,
  handleGetGroupList,
  grpList,
  setGrpList,
  setShowtab,
}) {
  const { t } = useTranslation();

  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showMember, setShowMember] = useState(false);
  const [showMemberIndex, setShowMemberIndex] = useState();

  const [editGrp, setEditGrp] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [predefaultData, setPredefaultData] = useState();
  const [grpMember, setGrpMember] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const [deleteIndex, setDeleteIndex] = useState();

  const handleDelete = () => {
    setShowDelete(!showDelete);
  };
  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const parts = paragraph?.split(new RegExp(`(${keyWord})`, "gi"));

      return parts?.map((part, index) =>
        part?.toLowerCase() === keyWord?.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "12px",
              letterSpacing: "0.26px",
              background: "#CEF56E",
              margin: "0px",
              color: "#000000",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  useEffect(() => {
    handleGetGroupList(searchKeyword);
  }, [searchKeyword]);

  const handleDeleteGroup = () => {
    const response = TrainzaApi.DeleteGroupList(isAuthenticated, deleteIndex);
    setIsLoading(true);
    response.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      setShowDelete(!showDelete);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("GRP_DELETED"), "success");
          handleGetGroupList(searchKeyword);

          setEditIndex();
        } else if (response.data.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response.data.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleGetGroupMember = (id, index) => {
    const response = TrainzaApi.groupMember(isAuthenticated, id);

    response.then((response) => {
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        if (response?.data?.count !== 0) {
          setShowMember(true);
          setShowMemberIndex(index);
        }

        setGrpMember(response?.data?.groupMembers);
      }
    });
  };

  return (
    <div>
      {isLoading && <Loader />}

      {grpList?.map((item, index) => {
        return (
          <React.Fragment>
            <div className={styles.grpBox} key={index}>
              <div className={styles.detailBox}>
                <div className={styles.grpDetail}>
                  <h1>
                    <HighlightKeyInParagraph
                      keyWord={searchKeyword}
                      paragraph={capitalizeFirstLetter(item?.name)}
                    />
                  </h1>
                  <h2>{item.subtitle}</h2>
                  <p>
                    {item.totalMembers} {t("MEMBER")} -{" "}
                    <span
                      onClick={() => {
                        if (index == showMemberIndex) {
                          setShowMember(false);
                          setShowMemberIndex();
                        } else {
                          handleGetGroupMember(item?.id, index);
                        }
                      }}
                    >
                      {t("VIEW")} {t("MEMBER")}
                    </span>
                  </p>
                </div>
                <div className={styles.grpOption}>
                  <img
                    src={CrossImg}
                    alt="delete"
                    onClick={() => {
                      handleDelete();
                      setDeleteIndex(item?.id);
                    }}
                  ></img>
                  <img
                    src={EditImg}
                    alt="edit"
                    onClick={() => {
                      if (index === editIndex) {
                        setEditGrp(false);
                        setEditIndex();
                      } else {
                        setEditGrp(true);
                        setEditIndex(index);
                        setPredefaultData(item);
                        setDeleteIndex(item?.id);
                      }
                    }}
                  ></img>
                </div>
              </div>
              {showMember && showMemberIndex === index && (
                <div>
                  {grpMember?.map((item, index) => {
                    return (
                      <div className={styles.memberBox} key={index}>
                        <div className={styles.memberBoxInfo}>
                          {" "}
                          <div className={styles.memberImg}>
                            <img
                              src={item?.image}
                              alt="memberImg"
                              width="46px"
                              height="46px"
                            ></img>
                          </div>
                          <p>
                            {capitalizeFirstLetter(item?.firstName)}{" "}
                            <span>{item?.lastName}</span>
                          </p>
                        </div>

                        <img src={CrossImg} alt="delete"></img>
                      </div>
                    );
                  })}

                  <button
                    className={styles.closeButton}
                    onClick={() => {
                      setShowMember(false);
                    }}
                  >
                    {t("CLOSE")}
                  </button>
                </div>
              )}
            </div>
            {editGrp && editIndex === index && (
              <EditGroup
                predefaultData={predefaultData}
                setEditIndex={setEditIndex}
                handleGetGroupList={handleGetGroupList}
                handleDeleteGroup={handleDeleteGroup}
                show={showDelete}
                handleClose={handleDelete}
                grpList={grpList}
                setGrpList={setGrpList}
              />
            )}
          </React.Fragment>
        );
      })}

      {grpList?.length === 0 && searchKeyword !== "" ? (
        <div className={styles.noDataFound}>
          <p>
            {t("NO_RESULTS")} <span>{t("FOR_SEARCH_QUERY")}</span>
          </p>
        </div>
      ) : (
        ""
      )}

      {grpList?.length === 0 && (searchKeyword === "") & !isLoading ? (
        <div>
          <div className={styles.noActiveGrp}>
            <p>
              {t("CURRNETLY_HAVE_NO_GRP")} - <span>{t("ADD_GRP")}</span>
            </p>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.addGrpSection}>
        <p>
          {grpList?.length} <span>{t("GROUPS_")}</span>
        </p>
        <button
          onClick={() => {
            setEditGrp(false);
            setEditIndex();
            setShowtab(2);
          }}
        >
          <p>
            {t("ADD")} <span>{t("GROUP")}</span>
          </p>
        </button>
      </div>

      {/* {isLoadMore ? (
        <div className={styles.inviteormore}>
          <button
            type="button"
            onClick={() => handleGetGroupList(pageNumber, searchKeyword)}
          >
            <p>
              {t("LOAD")} <span>{t("MORE")}</span>
            </p>
          </button>
        </div>
      ) : (
        ""
      )} */}

      <DeleteResult
        show={showDelete}
        handleClose={handleDelete}
        handleDeleteGroup={handleDeleteGroup}
      />
    </div>
  );
}

export default Groups;
