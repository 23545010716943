import React, { useState } from "react";
import styles from "./MembersPage.module.css";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import Members from "./Members";
import Invites from "./Invites";
import AddDetail from "./AddDetail";
import { useTranslation } from "react-i18next";
import { MEMBERS } from "../../Utils/MockData";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function MembersPage() {
  const { t } = useTranslation();
  const [showtab, setShowtab] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [key, setKey] = useState("1");

  const filterMembers = MEMBERS.filter(
    (item) =>
      item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.age.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.date.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 1
          ? t("MEMBERS")
          : showtab === 2
          ? t("MEMBERS > INVITES")
          : t("MEMBERS > ADD NEW")}
      </p>
      <div className={styles.devider}></div>
      <div className={styles.option}>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => {
            setKey(k);
          }}
          className="mb-3"
        >
          <Tab eventKey="1" title="Members"></Tab>
          <Tab eventKey="2" title="Invites"></Tab>
          <Tab
            eventKey="3"
            title={
              <img
                src={PlusIcon}
                alt="PlusIcon"
                width="35px"
                height="35px"
              ></img>
            }
          ></Tab>

          <Tab
            eventKey="1234"
            title={
              <input
                type="text"
                className={styles.search}
                placeholder={t("SEARCH_")}
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              ></input>
            }
          ></Tab>
        </Tabs>
      </div>
      <div className={styles.devider}></div>

      {/* =====TABS======== */}
      {showtab === 1 ? (
        <Members memberList={filterMembers} searchKeyword={searchKeyword} />
      ) : showtab === 2 ? (
        <Invites />
      ) : (
        <AddDetail />
      )}

      {/* =========== */}
      <h1 className={styles.memberLimitHead}>200 {t("MEMBER_LIMIT")}</h1>
      <p className={styles.optionLabel}>{t("UPGRADE_CANCEL")}</p>
      <p className={styles.manageHead}>{t("MANAGE_SUBSCRIPTION_TO_MANAGE")} </p>
      <button className={styles.manageSubscription}>
        <p>
          {t("MANAGE")} <span>{t("SUBSCRIPTION")}</span>
        </p>
      </button>
    </div>
  );
}

export default MembersPage;
