import React, { useEffect, useRef, useState } from "react";
import SelectImg from "../../../Assests/Images/SelectImg.png";
import DatePicker from "./DatePicker";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import styles from "./LogBook.module.css";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";

function EditLogbook() {
  const { t } = useTranslation();
  const previousRef = useRef();
  const nextRef = useRef();

  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);
  const [content, setContent] = useState("");
  const [activeBtn, setActiveBtn] = useState(0);
  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const currentDate = new Date();
  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };

  return (
    <div>
      <div className={styles.addWorkoutBox}>
        <div className={styles.scheduleHeaderForAdd}>
          <div className={styles.scheduleBoxOne}>
            <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
              <FaLessThan size="15" color="#ff4300" />
            </div>
            <div className={styles.scheduleDate}>
              <p>
                <span>{weekday}</span> {day} {month} {year}
              </p>
            </div>
            <div className={styles.iconHolder} onClick={callgoToNextMonth}>
              <FaGreaterThan size="15" color="#ff4300" />
            </div>
          </div>
          <MdOutlineCalendarMonth
            size="24"
            color="#FFFFFF"
            onClick={handleShowDate}
          />
        </div>

        <div className={styles.selectWorkoutBox}>
          <Form.Group className={styles.inputArea}>
            <Form.Label>
              <div className={styles.inputLabel}>
                <p>
                  {t("WORKOUT")} <span>{t("TYPE_")}</span>
                </p>
              </div>
            </Form.Label>
            <Form.Select className={styles.inputSelect}>
              <option>{t("DIS_CHEM_HALF_MARATHON_2024")}</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </Form.Select>
            <img src={SelectImg} alt="SelectImg"></img>
          </Form.Group>
        </div>

        <div className={styles.effortLevelBox}>
          <div className={styles.effortLevelHead}>
            <p>
              {t("EFFORT")} <span>{t("LEVEL")}</span>
            </p>
          </div>
          <div className={styles.effortLevelBtn}>
            <button
              className={
                activeBtn === 0
                  ? styles.effortLevelBtnActive
                  : styles.effortLevelBtnInactive
              }
              onClick={() => setActiveBtn(0)}
            >
              {t("EASY")}
            </button>
            <button
              className={
                activeBtn === 1
                  ? styles.effortLevelBtnActive
                  : styles.effortLevelBtnInactive
              }
              onClick={() => setActiveBtn(1)}
            >
              {t("NORMAL")}
            </button>
            <button
              className={
                activeBtn === 2
                  ? styles.effortLevelBtnActive
                  : styles.effortLevelBtnInactive
              }
              onClick={() => setActiveBtn(2)}
            >
              {t("HARD")}
            </button>
          </div>
        </div>

        <div className={styles.checkBoxAndLabelArea}>
          <Form.Check
            type="checkbox"
            defaultChecked={true}
            className={styles.checkbox}
          />
          <p>{t("DISTANCE_AND_TIME")}</p>
        </div>

        <div className={styles.timeDurationBox}>
          <p>{t("DISTANCE_")}</p>
          <div className={styles.distanceTravel}>
            <input></input>
            <p>.</p>
            <input></input>
          </div>
        </div>

        <div className={styles.timeDurationBox}>
          <p>{t("TIME_")}</p>
          <div className={styles.timeTaken}>
            <div className={styles.timeBox}>
              <input></input>
              <p>{t("HOURS")}</p>
            </div>

            <p>:</p>
            <div className={styles.timeBox}>
              <input></input>
              <p>{t("MINS")}</p>
            </div>
            <p>:</p>
            <div className={styles.timeBox}>
              <input></input>
              <p>{t("SECS")}</p>
            </div>
          </div>
        </div>

        <div className={styles.paceInfo}>
          <h1>
            {t("PACE")}: 0.00/{t("KM")}
          </h1>
          <p>{t("CLEAR_INPUTS")}</p>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.workoutNotesSection}>
          <p>
            {t("WORKOUT")} <span>{t("NOTES_")}</span>
          </p>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleEditorChange}
            modules={modules}
            formats={formats}
            placeholder={t("DETAILS_OF_YOUR_WORKOUT")}
            className={styles.quill123}
          />
        </div>

        <div className={styles.coachCommentSection}>
          <p>
            {t("COACH")} <span>{t("COMMENTS_")}</span>
          </p>
          <h1>{t("ADD_WORKOUT_COMMNET_BACK_TO_MEMBER_HERE")}</h1>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={handleEditorChange}
            modules={modules}
            formats={formats}
            placeholder={t("ADD_CMT_HERE")}
          />
        </div>

        <div className={styles.btnSection}>
          <div className={styles.cancelDeleteBtn}>
            <button>{t("CANCEL")}</button>
            <button>
              <RiDeleteBin6Line size="20" color="#000000" />
              {t("DELETE")}
            </button>
          </div>
          <button>{t("SAVE")}</button>
        </div>
      </div>
      <DatePicker
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
      />
    </div>
  );
}

export default EditLogbook;
