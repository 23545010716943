import React from "react";
import styles from "./MemberDetail.module.css";
import PersonImg from "../../../Assests/Images/Person.png";
import UserImg from "../../../Assests/Images/UserImg.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
function MemberDetail() {
  const { t } = useTranslation();
  return (
    <div>
      <div className={styles.detailHeading}>
        <img src={PersonImg} alt="personImg"></img>
        <p>Willen Van Ooosthuizen</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.detailSubHeading}>
        <p>
          Member <span>Details</span>
        </p>
      </div>

      <div className={styles.info}>
        <p>{t("FIRST_NAME")}</p>
        <h1>Willem</h1>
      </div>

      <div className={styles.info}>
        <p>{t("SURNAME_LAST_NAME")}</p>
        <h1>Van Ooosthuizen</h1>
      </div>

      <div className={styles.info}>
        <p>{t("CONTACT_NO")}</p>
        <h1>+27-082-412-3880</h1>
      </div>

      <div className={styles.info}>
        <p>{t("DOB_REQUIRED")}</p>
        <h1>23 September 1971</h1>
      </div>

      <div className={styles.info}>
        <p>{t("GENDER_REQUIRED")}</p>
        <h1>Male</h1>
      </div>

      <div className={styles.info}>
        <p>{t("HEIGHT_NOT_PUBLIC_FOR_COACH")}</p>
        <h1>4 ft / 1.8 m </h1>
      </div>

      <div className={styles.info}>
        <p>{t("WEIGHT_NOT_PUBLIC_FOR_COACH")}</p>
        <h1>57 kg / 11.7 st </h1>
      </div>

      <div className={styles.info}>
        <p>{t("COUNTRY")}</p>
        <h1>South Africa</h1>
      </div>

      <div className={styles.info}>
        <p>{t("STATE_PRIVINCE")}</p>
        <h1>Gauteng</h1>
      </div>

      <div className={styles.info}>
        <p>{t("TOWN_CITY")}</p>
        <h1>Bedfordview</h1>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.detailSubHeading}>
        <p>
          {t("PROFILE")} <span>{t("PICTURE")}</span>
        </p>
      </div>

      <div className={styles.profilePic}>
        <img src={UserImg} alt="UserImg"></img>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.detailSubHeading}>
        <p>
          {t("PERSONAL")} <span>{t("BESTS")}</span>
        </p>
      </div>

      <Row lg={3}>
        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                5{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>

        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                10{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>

        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                21{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>

        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                15{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>

        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                32{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>

        <Col>
          <div>
            <div className={styles.timeHead}>
              <p>
                42{t("KM")}
                <span> {t("BEST_TIME")}</span>
              </p>
            </div>
            <div className={styles.time}>
              <p>0:00:00</p>
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.devider}></div>

      <div className={styles.detailSubHeading}>
        <p>
          {t("WORKOUT")} <span>{t("GROUPS_")}</span>
        </p>
      </div>

      <div className="acceptTerms">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            className="form-check-label"
            for="flexCheckDefault"
            style={{
              fontFamily: "Manrope",
              fontSize: "16px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            A {t("SCHOOL")}
          </label>
        </div>
      </div>
      <div className="acceptTerms">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label
            className="form-check-label"
            for="flexCheckDefault"
            style={{
              fontFamily: "Manrope",
              fontSize: "16px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            B {t("SCHOOL")}
          </label>
        </div>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.detailSubHeading}>
        <p>
          {t("MEMBERSHIP")} <span>{t("INFO")}</span>
        </p>
      </div>

      <div className={styles.info}>
        <p>{t("MEMBERSHIP_VALID_UNTIL")}</p>
        <h1>23 September 2024</h1>
      </div>

      <div className={styles.info}>
        <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
        <h1>Active</h1>
      </div>

      <div className={styles.info}>
        <h1>{t("CAN_VIEW_OTHER_CLUB_MEMBER")}</h1>
        <h1>Active</h1>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.editSection}>
        <button>{t("EDIT")}</button>
      </div>
    </div>
  );
}

export default MemberDetail;
