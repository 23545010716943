import React, { useState, useEffect } from "react";
import styles from "./BrandLayout.module.css";
import { useTranslation } from "react-i18next";
import BrandInfo from "./BrandInfo/BrandInfo";
import Branding from "./Branding/Branding";
import Subscription from "./Subscription.js/Subscription";
import Password from "./Password/Password";
import { useContext } from "react";
import { UserContext } from "../../Context/UserContext";
import { useDispatch, useSelector } from "react-redux";
import { is_modal_open } from "../../redux/slices/userSlice";

function BrandLayout() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isAnyChange, setSaveChanges } = useContext(UserContext);

  const [tabIndex, setTabIndex] = useState(1);

  const [showtab, setShowtab] = useState(1);

  const handleTabChange = () => {
    setShowtab(tabIndex);
    setSaveChanges(false);
  };

  useEffect(() => {
    if (!isAnyChange) {
      handleTabChange();
    }
  }, [isAnyChange, tabIndex]);

  const handleTryToChangeTab = (index) => {
    setTabIndex(index);
    if (isAnyChange) {
      dispatch(is_modal_open());
    }
  };

  return (
    <div>
      <p className={styles.heading}>
        {showtab === 1 || showtab === 2
          ? t("BRAND INFO")
          : t("BRAND_INFO_SUBSCRIPTION")}
      </p>

      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <button
            className={showtab === 1 ? styles.members : styles.invites}
            onClick={() => handleTryToChangeTab(1)}
          >
            {t("BRAND_INFO")}
          </button>

          <button
            className={showtab === 2 ? styles.members : styles.invites}
            onClick={() => handleTryToChangeTab(2)}
          >
            {t("BRANDING_")}
          </button>

          <button
            className={showtab === 3 ? styles.members : styles.invites}
            onClick={() => handleTryToChangeTab(3)}
          >
            {t("SUBSCRIPTION_")}
          </button>

          <button
            className={showtab === 4 ? styles.members : styles.invites}
            onClick={() => handleTryToChangeTab(4)}
          >
            {t("PASSWORD")}
          </button>
        </div>
      </div>
      <div className={styles.devider}></div>

      {showtab === 1 ? (
        <BrandInfo />
      ) : showtab === 2 ? (
        <Branding />
      ) : showtab === 3 ? (
        <Subscription />
      ) : showtab === 4 ? (
        <Password />
      ) : (
        ""
      )}
    </div>
  );
}

export default BrandLayout;
