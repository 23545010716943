import styles from "./SignUp.module.css";
import styleVerify from "./Verification.module.css";
import logoImage from "../Assests/Images/logo.png";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUserToken } from "../redux/slices/userSlice";
import { STATUS_CODE } from "../Utils/StatusCode";
import { STATUS_MSG } from "../Utils/StatusMsg";
import Toster from "../Common/Toster/Toster";
import Loader from "../Common/Loader/Loader";
import OtpInput from "react-otp-input";
import TrainzaApi from "../Helpers/Api";
import { IoIosArrowRoundBack } from "react-icons/io";

function Verification() {
  const { handleSubmit, reset, formState, control } = useForm({
    mode: "onChange",
  });
  const location = useLocation();
  const isSignUp = location?.state?.token;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("eCode", data.emailcode);
    PostData.append("token", isSignUp);

    setIsLoading(true);

    let res = TrainzaApi.verification(PostData);
    res.then((response) => {
      setIsLoading(false);
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code === STATUS_CODE.SUCCESS) {
        navigate("/login");
        reset();
      } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
        Toster(response?.data?.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  useEffect(() => {
    if (!isSignUp) {
      navigate("/sign-up");
    }
  }, []);

  return (
    <div className={styles.bgSection}>
      {isLoading && <Loader />}
      <div className={styles.formSection}>
        <div className={styles.logoImg}>
          <img src={logoImage}></img>
        </div>

        <div className={styles.loginForm}>
          <div className={styles.heading}>
            <p>
              {t("BRAND_CLUB")} <span>{t("VERIFICATION")}</span>{" "}
            </p>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className={styleVerify.formInput}>
              <Form.Label>
                <p>
                  {t("EMAIL__")} <span>{t("CODE")}</span>
                </p>
              </Form.Label>

              <Controller
                name="emailcode"
                type="password"
                control={control}
                defaultValue=""
                rules={{
                  required: `${t("PLS_ENTER_EMAIL_CODE")}`,
                  minLength: {
                    value: 4,
                    message: `${t("EMAIL_CODE_MUST_4_DIGIT")}`,
                  },
                }}
                render={({ field }) => (
                  <OtpInput
                    inputStyle="otpStyiling"
                    {...field}
                    numInputs={4}
                    isInputNum
                    shouldAutoFocus={field.value.length < 4}
                    renderInput={(props) => (
                      <input {...props} inputMode="numeric" />
                    )}
                    onChange={(value) => {
                      let numbers = /^[0-9]+$/;
                      if (value.match(numbers) || value === "") {
                        field.onChange(value);
                      } else {
                        return false;
                      }
                    }}
                  />
                )}
              />

              {formState.errors.emailcode && (
                <span className="otpErrorMsg">
                  {formState.errors.emailcode.message}
                </span>
              )}
            </Form.Group>

            {/* {isPhone ? (
              <Form.Group className={styleVerify.formInput}>
                <Form.Label>
                  <p>
                    {t("PHONE")} <span>{t("CODE")}</span>
                  </p>
                </Form.Label>

                <Controller
                  name="phonecode"
                  type="password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: `${t("PLS_ENTER_PHONE_CODE")}`,
                    minLength: {
                      value: 4,
                      message: `${t("PHONE_CODE_MUST_4_DIGIT")}`,
                    },
                  }}
                  render={({ field }) => (
                    <OtpInput
                      inputStyle="otpStyiling"
                      {...field}
                      numInputs={4}
                      isInputNum
                      shouldAutoFocus={field.value.length < 4}
                      renderInput={(props) => (
                        <input {...props} inputMode="numeric" />
                      )}
                      onChange={(value) => {
                        let numbers = /^[0-9]+$/;
                        if (value.match(numbers) || value === "") {
                          field.onChange(value);
                        } else {
                          return false;
                        }
                      }}
                    />
                  )}
                />

                {formState.errors.phonecode && (
                  <span className="otpErrorMsg">
                    {formState.errors.phonecode.message}
                  </span>
                )}
              </Form.Group>
            ) : (
              ""
            )} */}

            <div className={styles.loginBtn}>
              <button type="submit">
                <p>
                  {t("VERIFY")} <span>{t("NOW")}</span>
                </p>
              </button>
            </div>
          </Form>
          <div className={styleVerify.goBackButton}>
            <button
              onClick={() => {
                navigate("/sign-up");
              }}
            >
              <IoIosArrowRoundBack />
              <p>BACK</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Verification;
