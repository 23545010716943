import styles from "./SignUp.module.css";
import logoImage from "../Assests/Images/logo.png";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdLockOutline } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { IoPersonOutline } from "react-icons/io5";
import { ValidationSchema } from "../Utils/ValidationSchema";
import GoogleLogo from "../Assests/Images/GoogleLogo.png";
import Shape from "../Assests/Images/Shape.png";
import { useNavigate } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { userSignUp } from "../redux/slices/userSlice";
import { STATUS_CODE } from "../Utils/StatusCode";
import { STATUS_MSG } from "../Utils/StatusMsg";
import Toster from "../Common/Toster/Toster";
import Loader from "../Common/Loader/Loader";
import { cleanData } from "../Utils/CommonFunction";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
function SignUp() {
  const { register, handleSubmit, reset, formState, setValue } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [shown, setShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shownConfirn, setShownConfirn] = useState(false);
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState("+27");
  const [password, setPassword] = useState("");
  const [conPassword, setConPassword] = useState("");
  const [accept, setAccept] = useState(true);

  const onSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("brandName", data.NAME);
    PostData.append("email", data.EMAIL);
    if (phone?.length > 5) {
      PostData.append("dialCd", dialCode);
      PostData.append("phn", cleanData(phone));
    } else {
      PostData.append("dialCd", "");
      PostData.append("phn", "");
    }

    PostData.append("password", data.PASSWORD);
    PostData.append("cnfPassword", data.CON_PASSWORD);

    if (
      password == conPassword &&
      (phone?.length > 6 || phone?.length == 1 || phone?.length == 0)
    ) {
      setIsLoading(true);
      dispatch(userSignUp(PostData)).then((res) => {
        setIsLoading(false);
        var response = res.payload;

        const ResponseCode = STATUS_MSG[response?.data.code];

        if (response?.code === STATUS_CODE.SUCCESS) {
          navigate("/verification", {
            state: { token: response?.data.token },
          });

          reset();
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      });
    }
  };

  const handelNumberChange = (value, country) => {
    setDialCode(country?.country?.dialCode);

    let phoneNumber = country?.inputValue?.slice(
      country.country.dialCode.length + 1,
      country.inputValue.length
    );

    setPhone(
      country?.inputValue?.slice(
        country.country.dialCode.length + 1,
        country.inputValue.length
      )
    );
    setValue("PHONE_INPUT", phoneNumber, {
      shouldValidate: true,
    });
  };
  return (
    <div className={styles.bgSection}>
      {isLoading && <Loader />}
      <div className={styles.formSection}>
        <div className={styles.logoImg}>
          <img src={logoImage}></img>
        </div>

        <div className={styles.loginForm}>
          <div className={styles.heading}>
            <p>
              {t("BRAND_CLUB_SIGN")} <span>{t("UP")}</span>{" "}
            </p>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className={styles.loginInput}>
              <Form.Label>
                <p>
                  {t("BRAND_NAME")} <span>({t("REQUIRED")})</span>
                </p>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("BRAND_NAME_")}
                autoFocus={true}
                {...register("NAME", ValidationSchema.NAME)}
                isInvalid={!!formState.errors.NAME}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.NAME?.message)}
              </Form.Control.Feedback>

              <IoPersonOutline />
            </Form.Group>

            <Form.Group className={styles.loginInput}>
              <Form.Label>
                <p>
                  {t("EMAIL_ADDRESS_")} <span>({t("REQUIRED")})</span>
                </p>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("EMAIL")}
                autoFocus={true}
                {...register("EMAIL", ValidationSchema.EMAIL)}
                isInvalid={!!formState.errors.EMAIL}
                onChange={(e) => {
                  setValue("EMAIL", e.target.value.replace(/\s/g, ""), {
                    shouldValidate: true,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.EMAIL?.message)}
              </Form.Control.Feedback>

              <MdOutlineMailOutline />
            </Form.Group>

            <Form.Group className={styles.loginInput}>
              <Form.Label>
                <p>
                  {t("MOBILE_NUMBER_")} <span>({t("OPTIONAL")})</span>
                </p>
              </Form.Label>

              <PhoneInput
                defaultCountry="za"
                defaultMask="... ... ........."
                onChange={(value, country) =>
                  handelNumberChange(value, country)
                }
              />
              <div className="validateError">
                {phone?.length > 1 && phone?.length < 7
                  ? t("MOBILE_NO_MUST_BE_ATLEAST_5_DIGIT")
                  : ""}
              </div>

              <div className={styles.selectCountry}>
                <RiArrowDropDownLine color="#8B8A8A" size="30" />
              </div>
            </Form.Group>

            <Form.Group className={styles.loginInput}>
              <Form.Label>
                <p>
                  {t("PASSWORD_")} <span>({t("REQUIRED")})</span>
                </p>
              </Form.Label>
              <MdLockOutline />

              <Form.Control
                type={shown ? "text" : "password"}
                placeholder={t("YOUR_PASSWORD")}
                autoFocus={true}
                {...register("PASSWORD", ValidationSchema.PASSWORD)}
                isInvalid={!!formState.errors.PASSWORD}
                onChange={(e) => {
                  setPassword(e.target.value.replace(/\s/g, ""));
                  setValue("PASSWORD", e.target.value.replace(/\s/g, ""), {
                    shouldValidate: true,
                  });
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.PASSWORD?.message)}
              </Form.Control.Feedback>

              <div className={styles.lockIcon}>
                {shown ? (
                  <FaEye color="#727272" onClick={() => setShown(!shown)} />
                ) : (
                  <FaEyeSlash
                    color="#727272"
                    onClick={() => setShown(!shown)}
                  />
                )}
              </div>
            </Form.Group>

            <Form.Group className={styles.loginInput12}>
              <MdLockOutline />

              <Form.Control
                type={shownConfirn ? "text" : "password"}
                placeholder={t("REPEAT_PASSWORD")}
                isInvalid={password !== conPassword}
                autoFocus={true}
                {...register("CON_PASSWORD", ValidationSchema.CON_PASSWORD)}
                onChange={(e) => {
                  setConPassword(e.target.value.replace(/\s/g, ""));
                  setValue("CON_PASSWORD", e.target.value.replace(/\s/g, ""), {
                    shouldValidate: true,
                  });
                }}
              />

              <Form.Control.Feedback type="invalid">
                {password !== conPassword &&
                  formState.isSubmitted &&
                  t("PASSWORD_NOT_MATCHED")}
              </Form.Control.Feedback>

              <div className={styles.lockIcon}>
                {shownConfirn ? (
                  <FaEye
                    color="#727272"
                    onClick={() => setShownConfirn(!shownConfirn)}
                  />
                ) : (
                  <FaEyeSlash
                    color="#727272"
                    onClick={() => setShownConfirn(!shownConfirn)}
                  />
                )}
              </div>
            </Form.Group>

            <div className="remeberChek">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  onChange={() => {
                    setAccept(!accept);
                  }}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  {t("ACCEPT_TERMS")}
                </label>
              </div>
            </div>

            <div className={styles.loginBtn}>
              <button disabled={accept}>
                <p>
                  {t("REGISTER")} <span>{t("NOW")}</span>
                </p>
              </button>
            </div>
          </Form>
          <div className={styles.signUpWith}>
            <p>{t("OR_SIGN_UP")}</p>
          </div>
          <div className={styles.signInOption}>
            <div className={styles.option}>
              <img
                src={GoogleLogo}
                alt="GoogleLogo"
                width="22px"
                height="22px"
              ></img>
            </div>
            <div className={styles.option}>
              <FaFacebookF size="20" />
            </div>
            <div className={styles.option}>
              <img src={Shape} alt="Shape" width="22px" height="22px"></img>
            </div>
          </div>
          <div className={styles.alreadySignin}>
            <p>
              {t("ALREADY_REGISTERED?")}{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
              >
                {t("SIGN_IN")}
              </span>
            </p>
            {/* <p>{t("Already registered? Sign In")}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
