import React, { useState } from "react";
import styles from "./AddWorkout.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import SelectImg from "../../../Assests/Images/SelectImg.png";
import ReactQuill from "react-quill";
import { MdOutlinePlayArrow } from "react-icons/md";
import { FaMountainSun } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
function WorkoutRequired() {
  const { t } = useTranslation();

  const [addManually, setAddManually] = useState(false);
  const [detail, setDetail] = useState(false);
  const [content, setContent] = useState("");

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <div>
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>2</p>
        </div>
        <h1>
          {t("WORKOUT ")}
          <span>({t("REQUIRED")})</span>
        </h1>
      </div>

      {/* WITHOUT ADD MANUALLY */}
      {!addManually && !detail && (
        <div className={styles.savedWorkoutBox}>
          <p>
            {t("SAVED")} <span>{t("WORKOUT")}</span>
          </p>

          <div className={styles.selectWorkout}>
            <Form.Select aria-label="Default select example">
              <option>
                <p>
                  {t("SELECT")} <span>{t("SAVED_WORKOUT")}</span>
                </p>
              </option>
              <option value="1">
                <p>one</p>
              </option>
              <option value="2">
                <p>two</p>
              </option>
              <option value="3">
                <p>three</p>
              </option>
            </Form.Select>

            <div
              className={styles.addBtnBox}
              onClick={() => {
                setAddManually(false);
                setDetail(true);
              }}
            >
              <div className={styles.iconHolder}>
                <FaPlus color="#ff4300" />
              </div>
            </div>

            <img src={SelectImg} alt="SelectImg"></img>
          </div>

          <div className={styles.workoutNoteThat}>
            <p>
              {t("QUICKLY_ADD_IN_A")} <span>{t("SAVED_WORKOUT_ABOVE")}</span> or{" "}
              <span>{t("ADD_MANUALLY_BELOW")}</span>
            </p>
          </div>

          <button
            onClick={() => {
              setAddManually(true);
              setDetail(false);
            }}
          >
            <p>
              {t("ADD")} <span>{t("MANUALLY")}</span>
            </p>
          </button>
        </div>
      )}

      {/* ADD WORKOUT MANUALLY */}
      {addManually && (
        <div>
          <div className={styles.savedWorkoutBoxManually}>
            <div className={styles.addManually}>
              <p>
                {t("SAVED")} <span>{t("WORKOUT")}</span>
              </p>

              <div className={styles.selectWorkoutManually}>
                <Form.Select aria-label="Default select example">
                  <option>
                    <p>
                      {t("SELECT")}
                      <span>{t("WORKOUT_TYPE")}</span>
                    </p>
                  </option>
                  <option value="1">
                    <p>one</p>
                  </option>
                  <option value="2">
                    <p>two</p>
                  </option>
                  <option value="3">
                    <p>three</p>
                  </option>
                </Form.Select>

                <img src={SelectImg} alt="SelectImg"></img>
              </div>

              <p>
                {t("WORKOUT")}
                <span> {t("DETAILS")}</span>
              </p>

              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                placeholder={t("DETAILS_OF_YOUR_WORKOUT")}
                className="WorkoutTextEditor"
              />

              <div className={styles.videoLabel}>
                <div className={styles.videoIcon}>
                  <MdOutlinePlayArrow color="#ffffff" />
                </div>
                <h1>{t("VIDEO")}</h1>
              </div>

              <div className={styles.videoNote}>
                <p>{t("ADD_OPTIONAL_VIDEO_FOR_YOUR_WORKOUT")}</p>
              </div>

              <div className={styles.addLink}>
                <p>{t("LINK_TO_VIDEO")}</p>
                <input placeholder={t("ADD_VIDEO_LINK")}></input>
              </div>

              <div className={styles.videoLabel}>
                <div className={styles.videoIcon}>
                  <FaMountainSun color="#ffffff" />
                </div>
                <h1>{t("VIDEO_")}</h1>
              </div>

              <div className={styles.videoNote}>
                <p>{t("ADD_OPTIONAL_IMAGE_FOR_YOUR_WORKOUT")}</p>
              </div>

              <div className={styles.uploadImage}>
                <button>
                  <p>
                    {t("UPLOAD")} <span>{t("IMAGE")}</span>
                  </p>
                </button>
              </div>
            </div>
          </div>{" "}
          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                onClick={() => {
                  setDetail(false);
                  setAddManually(false);
                }}
              >
                {t("CANCEL")}
              </button>
              <button>
                {" "}
                <RiDeleteBin6Line size="20" />
                {t("DELETE")}
              </button>
            </div>
            <button className={styles.saveWorkout}>
              <p>
                {t("SAVE")} <span>{t("WORKOUT")}</span>
              </p>
            </button>
          </div>
        </div>
      )}

      {/* GET DETAIL */}

      {detail && (
        <div>
          <div className={styles.savedWorkoutBoxManually}>
            <div className={styles.workoutDetail}>
              <h1>Running - Hard Hill Repeats</h1>
              <p>
                Easy warm up around club block, 4 x 100m pick ups with 50m walk
                between reps,
              </p>
              <p style={{ margin: "0px 0px 10px 0px" }}>
                15 x 150m in 24-27secs with 50m walk between reps, Easy cool
                down around club block.
              </p>
            </div>
          </div>{" "}
          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                onClick={() => {
                  setDetail(false);
                  setAddManually(false);
                }}
              >
                {t("CENCEL")}
              </button>
              <button>
                {" "}
                <RiDeleteBin6Line size="20" />
                {t("DELETE")}
              </button>
            </div>
            <button className={styles.saveWorkout}>
              <p>
                {t("SAVE")} <span>{t("WORKOUT")}</span>
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WorkoutRequired;
