import React, { useState } from "react";
import styles from "./Invites.module.css";
import { Col, Row } from "react-bootstrap";
import PlayStore from "../../Assests/Images/PlayStore.png";
import Huawei from "../../Assests/Images/HuaweiLogo.jpg";
import GalaxyLogo from "../../Assests/Images/GalaxyStore.png";
import { FaApple } from "react-icons/fa6";
import ProfileDemo from "../../Assests/Images/ProfileDemo.png";
import SendInvite from "./SendInvite";
import { SEND_INVITATION } from "../../Utils/MockData";
import CancelInvite from "./CancelInvite";
import { useTranslation } from "react-i18next";
function Invites() {
  const { t } = useTranslation();
  const [sendInvite, setSendInvite] = useState(false);
  const [cancelInvite, setCancelInvite] = useState(false);

  const handleSendInvite = () => {
    setSendInvite(!sendInvite);
  };

  const handleCancelInvite = () => {
    setCancelInvite(!cancelInvite);
  };

  const [searchKeyword, setSearchKeyword] = useState("");

  const filterMembers = SEND_INVITATION.filter(
    (item) =>
      item.name.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      item.email.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const parts = paragraph.split(new RegExp(`(${keyWord})`, "gi"));

      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              background: "#FF4300",
              margin: "0px",
              color: "#FFFFFF",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <h1 style={{ margin: "0px" }}>{renderHighlightedParagraph()}</h1>;
  }
  return (
    <div>
      <div className={styles.inviteLabel}>
        <p>{t("ASK_YOUR_MEMBER")}</p>
      </div>
      <div className={styles.CompanyHolder}>
        <Row lg={2}>
          <Col>
            <div className={styles.CompanySection}>
              <img src={PlayStore} alt="playStore"></img>
              <div className={styles.CompanyInfo}>
                <h1>Google Play</h1>
                <p>GET IT ON</p>
              </div>
            </div>
          </Col>
          <Col>
            <div className={styles.CompanySection}>
              <FaApple color="white" size="30" />
              <div className={styles.CompanyInfo}>
                <h1>Download on the</h1>
                <p>Apple Store</p>
              </div>
            </div>
          </Col>

          <Col>
            <div className={styles.CompanySection}>
              <img src={GalaxyLogo} alt="galaxy"></img>
              <div className={styles.CompanyInfo}>
                <h1>Available on</h1>
                <p>Galaxy Store</p>
              </div>
            </div>
          </Col>

          <Col>
            <div className={styles.CompanySection}>
              <img src={Huawei} alt="huiwei"></img>
              <div className={styles.CompanyInfo}>
                <h1>Explore IT ON</h1>
                <p>AppGalaxy</p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className={styles.searchBox}>
        <p>
          {t("SEARCH")} <span>{t("MEMBER_SMALL")}</span>
        </p>
        <input
          placeholder={t("SEARCH_MEMBER_NAME")}
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        ></input>
        <div className={styles.profleImg}>
          <img src={ProfileDemo} alt="ProfileDemo"></img>
        </div>

        {searchKeyword !== "" && (
          <React.Fragment>
            {filterMembers?.map((item, index) => {
              return (
                <div className={styles.searchResult} key={index}>
                  <div className={styles.searchInfo}>
                    <img src={ProfileDemo} alt="ProfileDemo"></img>
                    <div className={styles.userDetail}>
                      <h1>
                        {" "}
                        <HighlightKeyInParagraph
                          keyWord={searchKeyword}
                          paragraph={item.name}
                        />
                      </h1>
                      <p>{item.email}</p>
                    </div>
                  </div>
                  <button onClick={handleSendInvite}>{t("INVITE")}</button>
                </div>
              );
            })}
          </React.Fragment>
        )}
      </div>

      <div className={styles.pendingInvitesBox}>
        <div className={styles.pendingInvitesBoxLabel}>
          <h1>
            {t("PENDING")} <span>{t("INVITES")}</span>
          </h1>
          <p>{t("PENDING_INVITE_COUNTS")}</p>
        </div>

        <div>
          {SEND_INVITATION?.map((item, index) => {
            return (
              <div className={styles.searchResult} key={index}>
                <div className={styles.searchInfo}>
                  <img src={ProfileDemo} alt="ProfileDemo"></img>
                  <div className={styles.userDetail}>
                    <h1>{item.name}</h1>
                    <p>{item.email}</p>
                  </div>
                </div>
                <button onClick={handleCancelInvite}>{t("CANCEL")}</button>
              </div>
            );
          })}
        </div>
      </div>

      <SendInvite handleClose={handleSendInvite} show={sendInvite} />
      <CancelInvite handleClose={handleCancelInvite} show={cancelInvite} />
    </div>
  );
}

export default Invites;
