export const STATUS_CODE = {
  HEADER_IS_MISSING: 100,
  DEVICE_TYPE_NOT_ALLOWED: 101,
  INVALID_API_KEY: 102,
  AUTH_TOKEN_IS_REQUIRED: 103,
  INVALID_TOKEN: 104,
  VALIDATION_ERROR: 105,
  INVALID_LOGIN_CREDENTIAL: 111,
  EMAIL_EXIST: 112,
  SESSION_EXPIRED: 113,
  BRAND_NAME_EXIST: 114,
  INVALID_VERIFICATION_CODE: 115,
  FAILED_TO_SEND_EMAIL: 116,
  INVALID_BRAND_ID: 117,
  INVALID_IMAGE_SIZE: 118,
  INVALID_BRAND_FILE_EXTENSION: 119,
  INVALID_APP_LOGO_IMAGE_SIZE: 120,
  INVALID_APP_LOGO_FILE_EXTENSION: 121,
  APP_LOGO_REQUIRED: 122,
  COUNTRY_ID_IS_REQUIRED: 123,

  INVALID_GROUP_ID: 124,
  EMAIL_AND_PHONE_NUMBER_EXIST: 125,
  INVALID_MEMBER_ID: 126,
  INVALID_MEMBER_PICTURE_SIZE: 127,
  INVALID_MEMBER_FILE_EXTENSION: 128,
  GROUP_NAME_EXIST: 129,
  INVALID_PERSONAL_BESTS_OBJECT: 130,
  INVALID_BRANDS_OBJECT: 131,
  MEMBER_LIMIT_EXCEEDED: 132,
  THE_GROUP_IS_ALREADY_IN_USE_YOU_CANT_PERFORM_A_DELETE_OPERATION: 133,
  PHONE_NUMBER_EXIST: 134,
  PROFILE_PICTURE_REQUIRED: 135,
  INVALID_EVENT_ID: 136,
  INVALID_EVENT_PICTURE_SIZE: 137,
  INVALID_EVENT_FILE_EXTENSION: 138,
  INVALID_RESULT_DISTANCE_JSON_OBJECT: 139,
  INVALID_KEY_IN_RESULT_DISTANCE_JSON_OBJECT: 140,
  EVENT_NAME_ALREADY_EXIST: 141,
  MEMBER_IS_INACTIVE: 142,
  ACCOUNT_NOT_FOUND: 143,
  INVALID_PERSONAL_BESTS_JSON_OBJECT: 144,
  INVALID_BRANDS_JSON_OBJECT: 145,
  EVENT_GALLERY_REQUIRED: 146,
  INVALID_EVENT_GALLERY_PICTURE_SIZE: 147,
  INVALID_EVENT_GALLERY_FILE_EXTENSION: 148,
  BRANDS_FIELD_IS_REQUIRED: 149,
  INVALID_POLL_ANSWERS_JSON_OBJECT: 150,
  INVALID_KEY_IN_POLL_ANSWERS_JSON_OBJECT: 151,
  DUPLICATE_POLL_ANSWER_FOUND: 152,
  NUMBER_OF_POLL_ANSWER_CAN_BE_MAXIMUM_10: 153,
  INVALID_TRAINING_GROUPS_JSON_OBJECT: 154,
  ALREADY_A_MEMBER_OF_THIS_CLUB: 155,
  MEMBER_INVITATION_IS_ALREADY_SENT: 156,
  SUCCESS: 200,
  INTERNAL_SERVER_ERROR: 500,
};
