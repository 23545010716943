import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./BrandInfo.module.css";
import { Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import PhoneInput from "react-phone-input-2";
import { RiArrowDropDownLine } from "react-icons/ri";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useForm, useController } from "react-hook-form";
import { ValidationSchema } from "../../../Utils/ValidationSchema";
import { useTranslation } from "react-i18next";
import TrainzaApi from "../../../Helpers/Api";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import CrossImg from "../../../Assests/Images/Cross.png";
import { useDispatch, useSelector } from "react-redux";
import Toster from "../../../Common/Toster/Toster";
import Loader from "../../../Common/Loader/Loader";
import { STATUS_MSG } from "../../../Utils/StatusMsg";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { useNavigate } from "react-router-dom";
import {
  b64toBlob,
  capitalizeFirstLetter,
  cleanData,
  getRandom,
} from "../../../Utils/CommonFunction";
import CropImg from "../../../Common/Crop/CropImg";
import mime from "mime";
import { CONSTANT } from "../../../Utils/Constant";
import { Autocomplete } from "@react-google-maps/api";
import { UserContext } from "../../../Context/UserContext";

function BrandInfo() {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState,
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      BRAND_DETAIL: "",
    },
  });

  const fileInputRef = useRef();
  const autocomplete = useRef(null);
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;

  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultCountryId = "vbmOpe";

  const [userName, setUserName] = useState("");
  const [phone, setPhone] = useState();
  const [dialCode, setDialCode] = useState("+27");
  const [isLoading, setIsLoading] = useState(true);
  const [whatsappNo, setWhatsappNo] = useState();
  const [whatsappDialCode, setWhatsappDialCode] = useState("+27");
  const [isPhysicalAddress, setIsPhysicalAddress] = useState(0);
  const [isButtonAdded, setIsButtonAdded] = useState(0);

  const [sourceGeometry, setSourceGeometry] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  const saveModalOpen = useSelector((state) => state.user.open_modal);
  const { setIsAnyChange, saveChanges, setSaveChanges } =
    useContext(UserContext);

  const [previewMembershipImg, setPreviewMembershipImg] = useState(null);
  const [imageSrc, setImageSrc] = useState();
  const [isCropper, setIsCropper] = useState(false);

  const [countryMeta, setCountryMeta] = useState([]);
  const [stateMeta, setStateMeta] = useState([]);

  const [showCrop, setShowCrop] = useState(false);
  const [defaultCountry, setDefaultCountry] = useState("vbmOpe");
  const [selectCountry, setSelectCountry] = useState("");

  const [defaultState, setDefaultState] = useState("");

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const [position, setPosition] = useState({
    lat: "",
    lng: "",
  });

  const onMarkerDragEnd = (e) => {
    setIsAnyChange(true);
    setPosition({
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    });
  };

  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: "BRAND_DETAIL",
    control,
    rules: {
      required: "Please enter brand detail",
    },
  });

  const handleCloseCrop = () => setShowCrop(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIsCropper(true);

    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewMembershipImg(fileUrl);
      setImageSrc(fileUrl);
      setIsAnyChange(true);
    } else {
      setPreviewMembershipImg(null);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const getCountryMetaData = () => {
    const response = TrainzaApi.countryMetaData();
    response.then((res) => {
      setCountryMeta(res?.data?.countryMeta);
    });
  };

  useEffect(() => {
    getCountryMetaData();

    getBrandDetail();
  }, []);

  useEffect(() => {
    getStateMetaData(defaultCountry);
  }, [defaultCountry]);

  useEffect(() => {
    setValue("COUNTRY", defaultCountry);
  }, [countryMeta]);

  useEffect(() => {
    if (selectCountry != "" && selectCountry != defaultCountry) {
      setValue("STATE", "");
    } else {
      setValue("STATE", defaultState);
    }
  }, [stateMeta, defaultState]);

  useEffect(() => {
    if (isCropper == true) {
      setShowCrop(true);
    }
  }, [imageSrc]);

  const getStateMetaData = (id) => {
    const response = TrainzaApi.stateMetaData(id);
    response.then((res) => {
      setStateMeta(res?.data?.stateMeta);
    });
  };

  const getBrandDetail = () => {
    const response = TrainzaApi.brandDetail(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const brandInfo = response?.data?.brandInfo;
        if (brandInfo) {
          if (brandInfo.countryMeta) {
            setDefaultCountry(brandInfo?.countryMeta);
            setValue("COUNTRY", brandInfo?.countryMeta);
            setDefaultState(brandInfo?.stateMeta);
          } else {
            setDefaultCountry(defaultCountryId);
          }

          setValue("BRAND_NAME", brandInfo?.brandName);
          setUserName(brandInfo?.brandName);
          setValue("BRAND_DETAIL", brandInfo?.details);
          if (brandInfo?.phoneDialCode) {
            setDialCode(brandInfo?.phoneDialCode);
          }
          setPhone(brandInfo?.phoneNumber);

          if (brandInfo?.whatsappDialCode) {
            setWhatsappDialCode(brandInfo?.whatsappDialCode);
          }

          setWhatsappNo(brandInfo?.whatsappNumber);

          if (brandInfo?.contactEmail) {
            setValue("CONTACT_EMAIL", brandInfo?.contactEmail);
          }
          if (brandInfo?.webAddress) {
            setValue("WEBSITE_ADDRESS", brandInfo?.webAddress);
          }

          setValue("TOWN_CITY", brandInfo?.town);
          setIsPhysicalAddress(brandInfo?.isPhysicalAddress);
          setValue("PHYSICAL_ADDRESS", brandInfo?.address);
          setValue("GOOGLE_ADDRESS", brandInfo?.googleAddress);
          if (brandInfo?.latitude) {
            setPosition({
              lat: brandInfo?.latitude,
              lng: brandInfo?.longitude,
            });
          } else {
            setPosition({
              lat: -3.745,
              lng: -38.523,
            });
          }

          setSourceGeometry({
            address: brandInfo?.googleAddress,
            lat: brandInfo?.latitude,
            lng: brandInfo?.longitude,
          });

          setPreviewMembershipImg(brandInfo?.membershipPageImg);
          setImageSrc(brandInfo?.membershipPageImg);
          setIsButtonAdded(brandInfo?.isBtnAdded);
          setValue("BUTTON_LABEL", brandInfo?.btnLbl);
          setValue("BUTTON_Link", brandInfo?.btnLink);
        }
      }
    });
  };

  const OnSubmit = (data) => {
    const PostData = new FormData();
    PostData.append("brandName", cleanData(getValues("BRAND_NAME")));
    PostData.append("details", cleanData(getValues("BRAND_DETAIL")));
    PostData.append("phoneDialCode", dialCode);
    PostData.append("phoneNumber", cleanData(phone));
    PostData.append("whatsappDialCode", whatsappDialCode);
    PostData.append("whatsappNumber", cleanData(whatsappNo));

    PostData.append("contactEmail", cleanData(getValues("CONTACT_EMAIL")));
    PostData.append("webAddress", cleanData(getValues("WEBSITE_ADDRESS")));
    PostData.append("countryMeta", getValues("COUNTRY"));
    PostData.append("stateMeta", getValues("STATE"));
    PostData.append("town", getValues("TOWN_CITY"));

    if (
      isPhysicalAddress == 0 ||
      isPhysicalAddress == null ||
      isPhysicalAddress == undefined
    ) {
      PostData.append("isPhysicalAddress", 0);
      PostData.append("address", "");
    } else {
      PostData.append("isPhysicalAddress", 1);
      PostData.append("address", getValues("PHYSICAL_ADDRESS"));
    }

    PostData.append("googleAddress", cleanData(sourceGeometry.address));

    PostData.append("latitude", cleanData(sourceGeometry.lat));
    PostData.append("longitude", cleanData(sourceGeometry.lng));

    if (isCropper) {
      var blockCrop = previewMembershipImg.split(";");

      var cropContentTypes = blockCrop[0]?.split(":")[1]; // Get the content type
      var blockRealData = blockCrop[1].split(",")[1]; // Get the base64 data

      var cropBlobImg = b64toBlob(blockRealData, cropContentTypes);

      PostData.append(
        "membershipPageImg",
        cropBlobImg,
        getRandom() + "." + mime.getExtension(cropBlobImg.type)
      );
    } else {
      PostData.append("membershipPageImg", cleanData(imageSrc));
    }

    if (isButtonAdded == 0) {
      PostData.append("isBtnAdded", 0);
      PostData.append("btnLbl", "");
      PostData.append("btnLink", "");
    } else {
      PostData.append("isBtnAdded", 1);
      PostData.append("btnLbl", getValues("BUTTON_LABEL"));
      PostData.append("btnLink", getValues("BUTTON_Link"));
    }

    if (
      (phone == undefined || phone.length == 0 || phone?.length > 4) &&
      (whatsappNo == undefined ||
        whatsappNo.length == 0 ||
        whatsappNo?.length > 4)
    ) {
      setIsLoading(true);
      setIsCropper(false);
      TrainzaApi.brandInfo(isAuthenticated, PostData).then((response) => {
        setIsLoading(false);

        const ResponseCode = STATUS_MSG[response?.data?.code];

        if (
          response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
          response?.data?.code === STATUS_CODE.SESSION_EXPIRED
        ) {
          dispatch(userLogoutClear());
          Toster(t("SESSION_EXPIRED"), "error");
          navigate("/sign-up");
        } else {
          if (response?.code === STATUS_CODE.SUCCESS) {
            Toster(t("BRAND_INFO_ADDED"), "success");
            getBrandDetail();
            setIsAnyChange(false);
          } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
            Toster(response?.data?.message, "error");
            setSaveChanges(false);
          } else {
            Toster(t(ResponseCode), "error");
            setSaveChanges(false);
          }
        }
      });
    } else {
      setSaveChanges(false);
    }
  };

  useEffect(() => {
    if (saveChanges) {
      OnSubmit();
    }
  }, [saveModalOpen]);

  const handlePlaceSelected = (place, type) => {
    if (type == "sourceLocation") {
      setIsAnyChange(true);
      setSourceGeometry({
        address: autocomplete?.current?.getPlace()?.formatted_address,
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
      setPosition({
        lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
        lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
      });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission on Enter key press
      const selectedPlace = autocomplete.current?.getPlace();
      if (selectedPlace) {
        handlePlaceSelected(selectedPlace);
      }
    }
  };

  const fetchLocationName = async (lat, lng) => {
    const geocodingApiKey = placeApiKey; // Use your Google API key here
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${geocodingApiKey}`;

    const response = await fetch(geocodingUrl);
    const data = await response?.json();

    if (data.results && data.results.length > 0) {
      const formattedAddress = data.results[0].formatted_address;
      return formattedAddress;
    } else {
      return null; // No results found
    }
  };

  useEffect(() => {
    const fetchInitialLocationName = async () => {
      const locationName = await fetchLocationName(
        position?.lat,
        position?.lng
      );
      if (locationName) {
        // Set the source geometry with the default coordinates and location name
        setSourceGeometry({
          address: locationName,
          lat: position?.lat,
          lng: position?.lng,
        });
        setValue("GOOGLE_ADDRESS", locationName);
      }
    };

    fetchInitialLocationName();
  }, [position?.lat, position?.lng]);

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.brandHead}>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>

      <div className={styles.devider}></div>

      <p className={styles.brandLabel}>
        {t("BRAND")} <span>{t("INFO_")}</span>
      </p>

      <p className={styles.brandDescription}>
        {t("THIS_INFO_WILL_DISPLAYED_IN_APP")}
      </p>

      <div className={styles.devider}></div>
      <Form onSubmit={handleSubmit(OnSubmit)}>
        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              {t("BRAND_CLUB_")} <span>{t("NAME")}</span>
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("ENTER_NAME_OF_BRAND_CLUB")}
            {...register("BRAND_NAME", ValidationSchema.BRAND_NAME)}
            onChange={(e) => {
              setIsAnyChange(true);
              setUserName(e.target.value);
              setValue("BRAND_NAME", e.target.value);
            }}
            isInvalid={!!formState.errors.BRAND_NAME}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.BRAND_NAME?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        <div className={styles.devider}></div>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              {t("BRAND_CLUB_")} <span>{t("DETAILS")}</span>
            </p>
          </Form.Label>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={t("DETAIL_OF_YOUR_BRAND_CLUB_MEMBERSHIP")}
            className="brandTextEditor"
            ref={ref}
            modules={modules}
            formats={formats}
          />
          <div className="validateError">
            {error && <p className="error-message">{error.message}</p>}
          </div>
        </Form.Group>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span>{t("PHONE_NO")} - </span>
              {t("OPTIONAL_PHONE_NO_BUTTON")}
            </p>
          </Form.Label>
          <PhoneInput
            country="za"
            className="brandPhoneInput"
            value={`${dialCode} ${phone}`}
            onChange={(value, country) => {
              setIsAnyChange(true);
              let dialCode = country.dialCode;
              let phone = value.slice(dialCode.length, value.length);
              setDialCode(dialCode);
              setPhone(phone);
            }}
          />

          <div className="validateError">
            {phone?.length > 0 &&
              phone?.length <= 4 &&
              t("MOBILE_NO_MUST_BE_ATLEAST_5_DIGIT")}
          </div>

          <div className={styles.selectCountry}>
            <RiArrowDropDownLine color="#8B8A8A" size="30" />
          </div>
        </Form.Group>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span>{t("WHATSAPP_NO")} -</span>{" "}
              {t("OPTIONAL_WHATSAPP_NO_BUTTON")}
            </p>
          </Form.Label>
          <PhoneInput
            country="za"
            className="brandPhoneInput"
            value={`${whatsappDialCode} ${whatsappNo}`}
            onChange={(value, country) => {
              setIsAnyChange(true);
              let dialCode = country.dialCode;
              let phone = value.slice(dialCode.length, value.length);
              setWhatsappDialCode(dialCode);
              setWhatsappNo(phone);
            }}
          />

          <div className="validateError">
            {whatsappNo?.length > 0 &&
              whatsappNo?.length <= 4 &&
              t("WHATSAPP_NO_MUST_BE_ATLEAST_5_DIGIT")}
          </div>

          <div className={styles.selectCountry2}>
            <RiArrowDropDownLine color="#8B8A8A" size="30" />
          </div>
        </Form.Group>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span>{t("CONTACT_EMAIL")} -</span>{" "}
              {t("OPTIONAL_CONTACT_EMAIL_ADDRESS")}
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("CONTACT_EMAIL_ADDRESS")}
            {...register("CONTACT_EMAIL", ValidationSchema.CONTACT_EMAIL)}
            isInvalid={!!formState.errors.CONTACT_EMAIL}
            onChange={() => {
              setIsAnyChange(true);
            }}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.CONTACT_EMAIL?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span>{t("WEBSITE_ADDRESS")} -</span>{" "}
              {t("OPTIONAL_WEBSITE_ADDRESS_WITH_BUTTON")}
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={t("HTTP")}
            {...register("WEBSITE_ADDRESS", ValidationSchema.WEBSITE_ADDRESS)}
            isInvalid={!!formState.errors.WEBSITE_ADDRESS}
            onChange={() => {
              setIsAnyChange(true);
            }}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.WEBSITE_ADDRESS?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        <div className={styles.devider}></div>

        <div className={styles.locationRequired}>
          <p>
            {t("LOCATION")} <span>({t("REQUIRED__")})</span>
          </p>
        </div>

        <Form.Group className={styles.selectInput}>
          <Form.Label>
            <p>
              {t("COUNTRY")} ({t("REQUIRED_")})
            </p>
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            {...register("COUNTRY", ValidationSchema.COUNTRY)}
            isInvalid={!!formState.errors.COUNTRY}
            onChange={(e) => {
              setSelectCountry(e.target.value);
              setValue("COUNTRY", e.target.value);
              setIsAnyChange(true);
              getStateMetaData(e.target.value);
              setValue("STATE", "");
            }}
          >
            <option value="vbmOpe" defaultValue>
              South Africa
            </option>

            {countryMeta &&
              countryMeta.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
          </Form.Select>

          <Form.Control.Feedback type="invalid">
            {t(formState.errors.COUNTRY?.message)}
          </Form.Control.Feedback>
          <RiArrowDropDownLine color="#424242" />
        </Form.Group>

        <Form.Group className={styles.selectInput}>
          <Form.Label>
            <p>
              {t("STATE_PRIVINCE")} ({t("REQUIRED_")})
            </p>
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            {...register("STATE", ValidationSchema.STATE)}
            isInvalid={!!formState.errors.STATE}
            disabled={stateMeta?.length === 0}
            onChange={(e) => {
              setValue("STATE", e.target.value);
              setIsAnyChange(true);
            }}
          >
            <option value="" defaultValue>
              Select State/Province
            </option>
            {stateMeta &&
              stateMeta.map((item, index) => (
                <option value={item.id} key={index}>
                  {item.name}
                </option>
              ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.STATE?.message)}
          </Form.Control.Feedback>
          <RiArrowDropDownLine color="#424242" />
        </Form.Group>

        <Form.Group className={styles.selectInput}>
          <Form.Label>
            <p>
              {t("TOWN_CITY")} ({t("REQUIRED_")})
            </p>
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="Input Your City/Town"
            {...register("TOWN_CITY", ValidationSchema.TOWN_CITY)}
            isInvalid={!!formState.errors.TOWN_CITY}
            onChange={() => {
              setIsAnyChange(true);
            }}
          />
          <Form.Control.Feedback type="invalid">
            {t(formState.errors.TOWN_CITY?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        <div className={styles.devider}></div>

        <div className={styles.physicalAddress}>
          <Form.Check
            checked={isPhysicalAddress}
            className={styles.checkBoxArea}
            type={"checkbox"}
            label={
              <p>
                {t("PHYSICAL_ADDRESS")} <span>({t("OPTIONAL__")})</span>
              </p>
            }
            onChange={() => {
              setIsAnyChange(true);
              setIsPhysicalAddress(!isPhysicalAddress);
            }}
          />
        </div>

        {isPhysicalAddress ? (
          <Form.Group className={styles.formInput}>
            <Form.Label>
              <p>
                <span style={{ fontWeight: "500" }}>{t("ADDRESS_NAME")} -</span>{" "}
                {t("NAME_OF_LOCATION_REQUIRED")}
              </p>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={t("ENTER_LOCATION_NAME")}
              {...register(
                "PHYSICAL_ADDRESS",
                ValidationSchema.PHYSICAL_ADDRESS
              )}
              isInvalid={!!formState.errors.PHYSICAL_ADDRESS}
              onChange={() => {
                setIsAnyChange(true);
              }}
            />
            <Form.Control.Feedback type="invalid">
              {t(formState.errors.PHYSICAL_ADDRESS?.message)}
            </Form.Control.Feedback>
          </Form.Group>
        ) : (
          ""
        )}

        <Form.Group className={styles.formInput}>
          <Form.Label>
            <p>
              <span style={{ fontWeight: "500" }}>
                {t("GOOGLE_ADDRESS")} -{" "}
              </span>
              {t("INCLUDE_LINK_TO_LOCATION")}
            </p>
          </Form.Label>

          <Autocomplete
            placeApiKey={placeApiKey}
            onLoad={(auto) => {
              autocomplete.current = auto;
            }}
            onPlaceChanged={(place) => {
              handlePlaceSelected(place, "sourceLocation");
            }}
          >
            <input
              type="text"
              placeholder={t("TYPE_ADDRESS_OR_LEAVE_BLANK")}
              {...register("GOOGLE_ADDRESS", ValidationSchema.GOOGLE_ADDRESS)}
              isInvalid={!!formState.errors.GOOGLE_ADDRESS}
              onKeyDown={handleKeyDown}
            />
          </Autocomplete>

          <Form.Control.Feedback type="invalid">
            {t(formState.errors.GOOGLE_ADDRESS?.message)}
          </Form.Control.Feedback>
        </Form.Group>

        {/* GOOGLE MAP */}

        {position ? (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "140px",
              borderRadius: "4px",
              marginTop: "10px",
            }}
            center={position}
            zoom={16}
            draggable={true}
            onDragEnd={(e) => {
              setPosition({
                lat: e?.latLng?.lat(),
                lng: e?.latLng?.lng(),
              });
            }}
          >
            <Marker
              position={position}
              draggable={true}
              onDragEnd={onMarkerDragEnd}
            />
          </GoogleMap>
        ) : (
          ""
        )}

        <p className={styles.mapDescription}>
          {t("DRAG_PIN_TO_CORRENT_LOCATION")}
        </p>

        {/* =============== */}

        <div className={styles.devider}></div>

        <div className={styles.selectImgSection}>
          <p>{t("MEMBERSHIP_PAGE_IMG")}</p>
          <h1>{t("ADD_OPTIONAL_FEATURE_IMG")}</h1>
          <button type="button" onClick={handleButtonClick}>
            {t("SELECT_IMG")}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }} // Hide the file input element
            accept=".jpg, .jpeg, .gif, .png, .webp" // Allow only specified image formats
            onChange={handleFileChange} // Handle file input change
          />
        </div>

        {previewMembershipImg && (
          <div className={styles.previewMembershipImgBox}>
            <img
              src={previewMembershipImg}
              alt="previewMembershipImg"
              className={styles.previewImg}
            ></img>
            <img
              src={CrossImg}
              alt="CrossImg"
              className={styles.removeImg}
              onClick={() => {
                setPreviewMembershipImg(null);
                setImageSrc(null);
              }}
            ></img>
          </div>
        )}

        <div className={styles.devider}></div>

        <div className={styles.physicalAddress} style={{ display: "block" }}>
          <Form.Check
            checked={isButtonAdded}
            className={styles.addButton}
            type={"checkbox"}
            label={t("ADD_BUTTON")}
            onChange={() => {
              setIsButtonAdded(!isButtonAdded);
              setIsAnyChange(true);
            }}
          />
          <p>{t("ADD_OPTIONAL_BUTTON_TO_LINK")}</p>
        </div>

        {isButtonAdded ? (
          <React.Fragment>
            <Form.Group className={styles.formInput}>
              <Form.Label>
                <p>
                  <span style={{ fontWeight: "500" }}>{t("BUTTON_LABEL")}</span>
                </p>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("ADD_BUTTON_LABEL")}
                {...register("BUTTON_LABEL", ValidationSchema.BUTTON_LABEL)}
                isInvalid={!!formState.errors.BUTTON_LABEL}
                onChange={() => {
                  setIsAnyChange(true);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.BUTTON_LABEL?.message)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={styles.formInput}>
              <Form.Label>
                <p>
                  <span style={{ fontWeight: "500" }}>{t("LINK")}</span>
                </p>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("ADD_BUTTON_LINK")}
                {...register("BUTTON_Link", ValidationSchema.BUTTON_Link)}
                isInvalid={!!formState.errors.BUTTON_Link}
                onChange={() => {
                  setIsAnyChange(true);
                }}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.BUTTON_Link?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          </React.Fragment>
        ) : (
          ""
        )}

        <div className={styles.saveChanges}>
          <button type="submit">{t("SAVE_CHANGES_")}</button>
        </div>
      </Form>

      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        imageSrc={imageSrc}
        setSelectedImage={setPreviewMembershipImg}
        aspectRatio={CONSTANT.ASPECT_RATIO_IN_BRAND_INFO}
      />
    </div>
  );
}

export default BrandInfo;
