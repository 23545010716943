import React from "react";
import styles from "./Dashboard.module.css";
import { Col, Row } from "react-bootstrap";
import { GRIS_DATA } from "../../Utils/MockData";
import { useTranslation } from "react-i18next";
function Dashboard() {
  const { t } = useTranslation();

  return (
    <div>
      <p className={styles.heading}>{t("WELCOME")}</p>
      <div className={styles.devider}></div>
      <Row>
        {GRIS_DATA.map((item, index) => {
          return (
            <Col lg={4} md={4} sm={4} sx={6} key={index}>
              <div className={styles.gridView}>
                <p>{t(item.name)}</p>
                <h1>{item.count}</h1>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row>
        <Col lg={4}>
          <div className={styles.addThings}>
            <p>{t("ADD_NEWS")}</p>
          </div>
        </Col>
        <Col lg={4}>
          <div className={styles.addThings}>
            <p>{t("ADD_EVENT")}</p>
          </div>
        </Col>
        <Col lg={4}>
          <div className={styles.addThings}>
            <p>{t("ADD_MEMBER")}</p>
          </div>
        </Col>
      </Row>

      <h1 className={styles.limit}>200 {t("MEMBER_LIMIT")}</h1>
      <p className={styles.upgrade}>{t("UPGRADE_CANCEL")}</p>
      <p className={styles.manage}>{t("MANAGE_SUBSCRIPTION_TO_MANAGE")}. </p>
      <button
        className={styles.subscribeBtn}
        onClick={() => window.open("http://www.trainza.co.za/")}
      >
        <p>
          {t("MANAGE")} <span>{t("SUBSCRIPTION")}</span>
        </p>
      </button>
    </div>
  );
}

export default Dashboard;
