import React, { useState } from "react";
import styles from "./TrainingLayout.module.css";
import { useTranslation } from "react-i18next";
import PlusIcon from "../../Assests/Images/PlusIcon.png";
import { FaPlus } from "react-icons/fa";
import Training from "./TrainingTab/Training";
import Workout from "./WorkOutTab/Workout";

function TrainingLayout() {
  const { t } = useTranslation();

  const [showtab, setShowtab] = useState(1);

  return (
    <div>
      <p className={styles.heading}>{t("TRAINING")}</p>

      <div className={styles.devider}></div>

      <div className={styles.option}>
        <div className={styles.buttonSection}>
          <button
            className={showtab === 1 ? styles.members : styles.invites}
            onClick={() => setShowtab(1)}
          >
            {t("TRAINING")}
          </button>

          <button
            className={showtab === 2 ? styles.members : styles.invites}
            onClick={() => setShowtab(2)}
          >
            {t("WORKOUTS")}
          </button>

          <button
            className={showtab === 3 ? styles.members : styles.invites}
            onClick={() => setShowtab(3)}
          >
            {t("TYPES")}
          </button>

          {showtab === 4 ? (
            <div className={styles.addGrp}>
              <FaPlus color="#FFFFFF" />
            </div>
          ) : (
            <img
              src={PlusIcon}
              alt="PlusIcon"
              width="35px"
              height="35px"
              onClick={() => setShowtab(4)}
            ></img>
          )}
        </div>

        <input
          type="text"
          className={styles.search}
          placeholder={t("SEARCH_")}
        ></input>
      </div>
      <div className={styles.devider}></div>

      {showtab === 1 ? <Training /> : showtab === 2 ? <Workout /> : ""}
    </div>
  );
}

export default TrainingLayout;
