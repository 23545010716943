import React, { useState } from "react";
import styles from "./MembersPage.module.css";
import Person from "../../Assests/Images/Person.png";
import Cross from "../../Assests/Images/Cross.png";
import Edit from "../../Assests/Images/Edit.png";
import DeleteMember from "./DeleteMember";
import LimitReached from "./LimitReached";
import { useTranslation } from "react-i18next";
import DeleteResult from "./MemberDetail/DeleteResult";
import { capitalizeFirstLetter } from "../../Utils/CommonFunction";

function Members({ memberList, searchKeyword }) {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [showLimit, setShowLimit] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  const handleCloseLimit = () => setShowLimit(false);
  const handleShowLimit = () => setShowLimit(true);

  function HighlightKeyInParagraph({ keyWord, paragraph }) {
    const renderHighlightedParagraph = () => {
      const parts = paragraph.split(new RegExp(`(${keyWord})`, "gi"));

      return parts.map((part, index) =>
        part.toLowerCase() === keyWord.toLowerCase() ? (
          <span
            key={index}
            style={{
              fontFamily: "Manrope",
              fontWeight: "600",
              fontSize: "15px",
              letterSpacing: "0.3px",
              background: "#CEF56E",
              margin: "0px",
            }}
          >
            {part}
          </span>
        ) : (
          part
        )
      );
    };

    return <p style={{ margin: "0px" }}>{renderHighlightedParagraph()}</p>;
  }

  return (
    <div>
      {/* ============= */}

      <div className={styles.summary}>
        <div className={styles.personalDetail}>
          <p>{t("LIST_BY")} -</p>
          <h1>{t("NAME")}</h1>
          <p>
            - {t("SURNAME")} - {t("AGE")} - {t("EXPIRY_DATE")}
          </p>
        </div>
        <h1 className={styles.memberLimit}>{t("MEMBER_LIMIT")} - 0/200</h1>
      </div>
      <div className={styles.devider}></div>
      {memberList?.map((info, index) => {
        return (
          <div
            className={styles.detail}
            key={index}
            style={{
              backgroundColor:
                info.name === "pablo escobar" ? "#DDDDDD" : "#FFFFFF",
            }}
          >
            <div className={styles.info} key={index}>
              <img src={Person} alt="Person"></img>
              <div className={styles.infoOfMember}>
                <p className={styles.name}>
                  {" "}
                  <HighlightKeyInParagraph
                    keyWord={searchKeyword}
                    paragraph={capitalizeFirstLetter(info.name)}
                  />
                </p>
                <p className={styles.gender}>Male - {info.age}</p>
                <p className={styles.date}>{info.date}</p>
              </div>
            </div>
            <div className={styles.editCross}>
              <img src={Cross} alt="cross" onClick={handleShowDelete}></img>
              <img src={Edit} alt="edit"></img>
            </div>
          </div>
        );
      })}

      {/* MO RESULT FOUND FOR SEARCHING */}
      {memberList.length === 0 ? (
        <div className={styles.noDataFound}>
          <p>
            No Results <span>for search query - Change your search query</span>
          </p>
        </div>
      ) : (
        ""
      )}

      {/* INVITE AND LOAD MORE SECTION */}
      {!searchKeyword ? (
        <div className={styles.inviteormore}>
          <div className={styles.membersCount}>
            <button onClick={() => handleShowLimit()}>
              <p>
                {t("INVITE")} <span>{t("MEMBER___")}</span>
              </p>
            </button>
            <p>
              76 <span>{t("MEMBER")}</span>
            </p>
            <button>
              <p>
                {t("LOAD")} <span>{t("MORE")}</span>
              </p>
            </button>
          </div>
        </div>
      ) : (
        ""
      )}

      <DeleteResult handleClose={handleCloseDelete} show={showDelete} />
      <LimitReached handleClose={handleCloseLimit} show={showLimit} />
    </div>
  );
}

export default Members;
