import React, { useEffect, useRef, useState } from "react";
import styles from "./LogBook.module.css";
import profileImg from "../../../Assests/Images/ProfileDemo.png";
import { FaLessThan } from "react-icons/fa6";
import { FaGreaterThan } from "react-icons/fa6";
import { MdOutlineCalendarMonth } from "react-icons/md";
import { TbMessage } from "react-icons/tb";
import { MdOutlineModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";

import EditLogbook from "./EditLogbook";
import DatePicker from "./DatePicker";
import { useTranslation } from "react-i18next";
function LogBook() {
  const { t } = useTranslation();
  const previousRef = useRef();
  const nextRef = useRef();
  const [addLogbook, setAddLogbook] = useState(false);
  const [weekday, setWeekday] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [showDate, setShowDate] = useState(false);

  const handleShowDate = () => setShowDate(true);

  const handleCloseDate = () => setShowDate(false);

  const currentDate = new Date();

  const daysOfWeek = [
    t("SUNDAY"),
    t("MONDAY"),
    t("TUESDAY"),
    t("WEDNESDAY"),
    t("THURSDAY"),
    t("FRIDAY"),
    t("SATURDAY"),
  ];
  const monthsOfYear = [
    t("JANUARY"),
    t("FEB"),
    t("MARCH"),
    t("APRIL"),
    t("MAY"),
    t("JUNE"),
    t("JULY"),
    t("AUGUST"),
    t("SEP"),
    t("OCT"),
    t("NOV"),
    t("DEC"),
  ];

  useEffect(() => {
    setWeekday(daysOfWeek[currentDate.getDay()]);
    setDay(currentDate.getDate());
    setMonth(monthsOfYear[currentDate.getMonth()]);
    setYear(currentDate.getFullYear());
  }, []);

  const callgoToPreviousMonth = () => {
    previousRef.current.handleCallPrevious();
  };

  const callgoToNextMonth = () => {
    nextRef.current.handleCallNext();
  };
  return (
    <div>
      <div className={styles.userName}>
        <img src={profileImg} alt="profileImg"></img>
        <p>Willem Van Ooosthuiz</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.scheduleHeader}>
        <div className={styles.scheduleBoxOne}>
          <div className={styles.iconHolder} onClick={callgoToPreviousMonth}>
            <FaLessThan size="15" color="#000000" />
          </div>
          <div className={styles.scheduleDate}>
            <p>
              <span>{weekday}</span> {day} {month} {year}
            </p>
          </div>
          <div className={styles.iconHolder} onClick={callgoToNextMonth}>
            <FaGreaterThan size="15" color="#000000" />
          </div>
        </div>
        <MdOutlineCalendarMonth
          size="24"
          color="#FFFFFF"
          onClick={handleShowDate}
        />
      </div>

      <div className={styles.scheduleInfoBox}>
        <div className={styles.typeAndLevelBox}>
          <div className={styles.typeAndLevelBoxLabel}>
            <p>
              {t("WORKOUT_")} <span>{t("TYPE")}</span>
            </p>
          </div>
          <div className={styles.typeAndLevelBoxText}>
            <p>Running - Hill Training</p>
          </div>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.typeAndLevelBox}>
          <div className={styles.typeAndLevelBoxLabel}>
            <p>
              {t("EFFORT")} <span>{t("LEVEL")}</span>
            </p>
          </div>
          <div className={styles.typeAndLevelBoxText}>
            <p>Hard</p>
          </div>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.typeAndLevelBoxLabel}>
          <div className={styles.typeAndLevelBox}>
            <div className={styles.typeAndLevelBoxLabel}>
              <p style={{ fontWeight: "400" }}>
                {t("WORKOUT_")} <span>{t("NOTES")}</span>
              </p>
            </div>
            <div className={styles.workoutNotes}>
              <p>
                Curabitur metus dolor, lacinia sit amet ante eget, pharetra
                mollis sem. Fusce imperdiet nibh sed posuere finibus. Duis neque
                orci, ullamcorper eu ligula sed, cursus euismod ipsum. Interdum
                et malesuada fames ac ante ipsum primis in faucibus. Aliquam
                auctor, quam eu vehicula blandit, magna nisi condimentum ante,
                at faucibus nunc diam sit amet odio. Donec ac tempus quam, eget
                scelerisque dui. Curabitur mi lorem, convallis ac est quis,
                consectetur rhoncus quam. Proin gravida nunc vel tellus maximus
                dictum. Cras molestie ut velit sed rho.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.scheduleInfoBoxFooter}>
        <button>
          <TbMessage size="20" color="#000000" />
          <p>{t("COACH")}</p>
        </button>
        <button>
          <MdOutlineModeEdit size="20" color="#000000" />
          <p>{t("EDIT")}</p>
        </button>
      </div>

      <div className={styles.workoutDetail}>
        <div className={styles.workoutDetailLabel}>
          <p>
            {t("WORKOUT_")} <span>{t("TYPE")}</span>
          </p>
          <h1>Gym - Weight Training</h1>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.workoutDetailLabel}>
          <p>
            {t("EFFORT")} <span>{t("LEVEL")}</span>
          </p>
          <h1>Hard</h1>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.duration}>
          <div className={styles.durationLabel}>
            <p>{t("DIST")}</p>
            <h1>13.3{t("KM")}</h1>
          </div>

          <div className={styles.durationLabel}>
            <p>{t("TIME_")}</p>
            <h1>2:23:21</h1>
          </div>

          <div className={styles.durationLabel}>
            <p>{t("PACE")}</p>
            <h1>5:44</h1>
          </div>
        </div>

        <div className={styles.devider}></div>

        <div className={styles.workoutDetailLabel}>
          <p>
            {t("WORKOUT_")} <span>{t("NOTES")}</span>
          </p>
          <div className={styles.workoutNotesDetail}>
            <p>
              Curabitur metus dolor, lacinia sit amet ante eget, pharetra mollis
              sem. Fusce imperdiet nibh sed posuere finibus. Duis neque orci,
              ullamcorper eu ligula sed, cursus euismod ipsum. Interdum et
              malesuada fames ac ante ipsum primis in faucibus. Aliquam auctor,
              quam eu vehicula blandit, magna nisi condimentum ante, at faucibus
              nunc diam sit amet odio. Donec ac tempus quam, eget scelerisque
              dui. Curabitur mi lorem, convallis ac est quis, consectetur
              rhoncus quam. Proin gravida nunc vel tellus maximus dictum. Cras
              molestie ut velit sed rho.
            </p>
          </div>
        </div>

        <div className={styles.coachCommentBox}>
          <p>
            {t("COACH_")} <span>{t("COMMENTS")}</span>
          </p>
          <h1>
            Great Training Bob! Just be careful to warm up before the fartleks
            to aboid injury again. Hope your are stretching your achilies each
            month.{" "}
          </h1>
        </div>

        <div className={styles.optionBox}>
          <button>
            <RiDeleteBin6Line size="20" color="#000000" />
            {t("DELETE")}
          </button>
          <button style={{ padding: "0px 20px" }}>
            <MdOutlineModeEdit size="20" color="#000000" /> {t("EDIT")}
          </button>
        </div>
      </div>

      <div className={styles.addWorkout}>
        <div className={styles.addWorkoutContent}>
          <button
            className={styles.addWorkoutBtn}
            onClick={() => setAddLogbook(!addLogbook)}
          >
            <p>
              {t("ADD")} <span>{t("WORKOUT")}</span>
            </p>
          </button>
          <div className={styles.displayResult}>
            <p>
              2 <span>{t("RESULTS_")}</span>
            </p>
          </div>
        </div>
      </div>
      {addLogbook ? <EditLogbook /> : ""}
      <DatePicker
        show={showDate}
        handleClose={handleCloseDate}
        setWeekday={setWeekday}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        previousRef={previousRef}
        nextRef={nextRef}
      />
    </div>
  );
}

export default LogBook;
