import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../AppComponent/Dashboard/Dashboard";
import CommonLayout from "../Common/CommonLayout/CommonLayout";
import SignUp from "../AuthComponent/SignUp";
import MembersPage from "../AppComponent/Members/MembersPage";
import MemberDetailLayout from "../AppComponent/Members/MemberDetail/MemberDetailLayout";
import GroupLayout from "../AppComponent/Groups/GroupLayout";
import Login from "../AuthComponent/Login";
import TrainingLayout from "../AppComponent/Training/TrainingLayout";
import { useSelector } from "react-redux";
import Verification from "../AuthComponent/Verification";
import BrandLayout from "../AppComponent/Brand/BrandLayout";
import SaveChangesModal from "../AppComponent/Brand/Branding/SaveChangesModal";
export default function Router() {
  const isAuthenticated = useSelector((state) => state.user.userToken);

  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <CommonLayout />
            ) : (
              <Navigate to="/sign-up" replace={true} />
            )
          }
        >
          <Route path="/" element={<Navigate to="/dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="members" element={<MembersPage />} />
          <Route
            path="members/member-detail"
            element={<MemberDetailLayout />}
          />
          <Route path="groups" element={<GroupLayout />} />
          <Route path="training" element={<TrainingLayout />} />
          <Route path="brand" element={<BrandLayout />} />
        </Route>

        {!isAuthenticated && <Route path="/sign-up" element={<SignUp />} />}

        <Route path="*" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<Login />} />

        <Route path="/verification" element={<Verification />} />
      </Routes>

      <SaveChangesModal />
    </React.Fragment>
  );
}
