import Modal from "react-bootstrap/Modal";
import styles from "../LimitOrDeleteModal.module.css";
import { useTranslation } from "react-i18next";

function DeleteResult({ show, handleClose, handleDeleteGroup }) {
  const { t } = useTranslation();
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ background: "#F5F4F4" }}>
          <Modal.Title>
            <div className={styles.modalHead}>
              <p>
                {t("CONFIRM")} <span>{t("DELETE")}</span>
              </p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={styles.modalBody}>
            <p>
              {t("YOU_ARE_SURE")} <span>{t("THIS_CANNOT_BE_UNDONE")}</span>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ background: "#F5F4F4" }}>
          <div className={styles.btnSection}>
            <button className={styles.close} onClick={handleClose}>
              {t("CANCEL")}
            </button>
            <button className={styles.delete} onClick={handleDeleteGroup}>
              <p>
                {t("CONFIRM")} <span>{t("DELETE")}</span>
              </p>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteResult;
