import React from "react";
import styles from "./Workout.module.css";
import CrossImg from "../../../Assests/Images/Cross.png";
import EditImg from "../../../Assests/Images/Edit.png";
import { WORKOUT_POINT } from "../../../Utils/MockData";
function Workout() {
  return (
    <div>
      {WORKOUT_POINT?.map((item, index) => {
        return (
          <div
            className={styles.workoutSection}
            key={index}
            style={{ background: item.id === 4 ? "#DAF68C" : "#ffffff" }}
          >
            <p>{item.point}</p>
            <div className={styles.iconHolder}>
              <img src={CrossImg} alt="CrossImg"></img>
              <img src={EditImg} alt="EditImg"></img>
            </div>
          </div>
        );
      })}
      <div className={styles.btnBox}>
        <button>
          <p>
            Load <span>More</span>
          </p>
        </button>
        <p>
          34 <span>Saved Workout/s</span>
        </p>
        <button>
          <p>
            Add <span>Workout</span>
          </p>
        </button>
      </div>
    </div>
  );
}

export default Workout;
