import React, { useRef, useState } from "react";
import styles from "./MembersPage.module.css";
import { Form, Row, Col } from "react-bootstrap";
import SelectImg from "../../Assests/Images/SelectImg.png";
import ProfileDemo from "../../Assests/Images/ProfileDemo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CropImg from "../../Common/Crop/CropImg";

function AddDetail() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);

  const fileInputRef = useRef(null);

  const [phoneDialCode, setPhoneDialCode] = useState("27");
  const [phoneCountryCode, setPhoneCountryCode] = useState("Za");

  const [showCrop, setShowCrop] = useState(false);

  const handleCloseCrop = () => setShowCrop(false);
  const handleShowCrop = () => setShowCrop(true);

  const handleInputChangeForMinSecs = (event) => {
    const inputElement = event.target;
    const currentValue = inputElement.value;

    // Sanitize the input by removing non-digit characters and limiting to the first 2 characters
    const sanitizedValue = currentValue.replace(/\D/g, "").slice(0, 2);

    // Convert the sanitized value to a number for comparison
    const sanitizedNumber = parseInt(sanitizedValue, 10);

    // Check if the sanitized value is different from the current value or if it's greater than 60
    if (sanitizedValue !== currentValue || sanitizedNumber > 60) {
      // If so, set the input element value to the sanitized value or reset to an empty string if it's greater than 60
      inputElement.value = sanitizedNumber > 60 ? "" : sanitizedValue;
    }
  };

  const handleInputChangeForHours = (event) => {
    const inputElement = event.target;
    const currentValue = inputElement.value;

    const sanitizedValue = currentValue.replace(/\D/g, "").slice(0, 3);

    if (sanitizedValue !== currentValue) {
      inputElement.value = sanitizedValue;
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const capitalizeWholeWord = (string) => {
    return string.toUpperCase();
  };

  return (
    <div>
      <div className={styles.headingAddMember}>
        <p>{t("ADD_NEW_MEMBER")}</p>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.DetailHead}>
        <p>{t("MEMBER_SMALL")} </p>
        <h1>{t("DETAILS")}</h1>
      </div>

      <div className={styles.devider}></div>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("FIRST_NAME")}</Form.Label>
        <Form.Control type="text" placeholder={t("FIRST_NAME")} />
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("SURNAME_LAST_NAME")}</Form.Label>
        <Form.Control type="text" placeholder={t("SURNAME_LAST_NAME")} />
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("Email")}</Form.Label>
        <Form.Control type="email" placeholder={t("YOUR_EMAIL_ADDRESS")} />
      </Form.Group>

      <div className="acceptTerms">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("SHARE_PUBLICLY")}
          </label>
        </div>
      </div>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("CONTACT_NO")}</Form.Label>
        <p>
          {capitalizeWholeWord(phoneCountryCode)} +{phoneDialCode}
        </p>
        <PhoneInput
          country={"us"}
          className="PhoneInputArea"
          onChange={(value, country) => {
            let dialCode = country.dialCode;
            let phone = value.slice(dialCode.length, value.length);
            setPhoneCountryCode(country.countryCode);
            setPhoneDialCode(dialCode);
          }}
        />
        <img
          src={SelectImg}
          alt="SelectImg"
          className={styles.phoneSelectImg}
        ></img>
      </Form.Group>

      <div className="acceptTerms">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("SHARE_PUBLICLY")}
          </label>
        </div>
      </div>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("DOB_REQUIRED")}</Form.Label>
        <Form.Control type="date" />
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("GENDER_REQUIRED")}</Form.Label>
        <div className={styles.checkBoxArea}>
          <Form.Check
            inline
            label={t("MALE")}
            name="group1"
            type={"radio"}
            id={`inline-${"radio"}-1`}
          />
          <Form.Check
            inline
            label={t("FEMALE")}
            name="group1"
            type={"radio"}
            id={`inline-${"radio"}-2`}
          />
          <Form.Check
            inline
            label={t("OTHER")}
            name="group1"
            type={"radio"}
            id={`inline-${"radio"}-3`}
          />
        </div>
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("HEIGHT_NOT_PUBLIC_FOR_COACH")}</Form.Label>
        <Form.Select aria-label="Default select example">
          <option className={styles.defaultOption}>{t("SELECT_HEIGHT")}</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        <img src={SelectImg} alt="SelectImg" className={styles.selectImg}></img>
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("WEIGHT_NOT_PUBLIC_FOR_COACH")}</Form.Label>
        <Form.Select aria-label="Default select example">
          <option className={styles.defaultOption}>{t("SELECT_WEIGHT")}</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        <img src={SelectImg} alt="SelectImg" className={styles.selectImg}></img>
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("COUNTRY_REQUIRED")}</Form.Label>
        <Form.Select aria-label="Default select example">
          <option className={styles.defaultOption}>
            {t("SELECT_COUNTRY")}
          </option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        <img src={SelectImg} alt="SelectImg" className={styles.selectImg}></img>
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("STATE_PROVINCE")}</Form.Label>
        <Form.Select aria-label="Default select example">
          <option className={styles.defaultOption}>
            {t("SELECT_STATE_PROVINCE")}
          </option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        <img src={SelectImg} alt="SelectImg" className={styles.selectImg}></img>
      </Form.Group>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("TOWN_CITY_REQ")}</Form.Label>
        <Form.Control type="text" placeholder={t("INPUT_YOUR_CITY_TOWN")} />
      </Form.Group>

      <div className={styles.devider}></div>

      <div className={styles.DetailHead}>
        <p>{t("PROFILE")} </p>
        <h1>{t("PICTURE")}</h1>
      </div>

      <div className={styles.profileSection}>
        <img
          src={selectedImage ? selectedImage : ProfileDemo}
          alt="ProfileDemo"
        ></img>
        <div className={styles.profileSectionOption}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
            ref={fileInputRef}
          />
          <button onClick={handleButtonClick}>{t("SELECT_PHOTO")}</button>

          {selectedImage && (
            <React.Fragment>
              <p style={{ cursor: "pointer" }} onClick={handleShowCrop}>
                {t("CROP_PHOTO")}
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setSelectedImage(null);
                }}
              >
                {t("REMOVE_PHOTO")}
              </p>
            </React.Fragment>
          )}
        </div>
      </div>
      <CropImg
        handleClose={handleCloseCrop}
        show={showCrop}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        imageSrc={imageSrc}
        setImageSrc={setImageSrc}
      />

      <div className={styles.DetailHead}>
        <p>{t("PERSONAL")} </p>
        <h1>{t("BESTS")}</h1>
      </div>

      <Row lg={2}>
        <Col>
          <div className={styles.timeHead}>
            <p>
              5{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>

          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              />
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              />
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>

        <Col>
          <div className={styles.timeHead}>
            <p>
              10{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>

          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>

        <Col>
          <div className={styles.timeHead}>
            <p>
              15{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>
          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>

        <Col>
          <div className={styles.timeHead}>
            <p>
              21{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>
          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>

        <Col>
          <div className={styles.timeHead}>
            <p>
              32{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>
          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>

        <Col>
          <div className={styles.timeHead}>
            <p>
              42{t("KM")} <span>{t("BEST_TIME")}</span>
            </p>
          </div>
          <div className={styles.timeInput}>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForHours}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("HOURS")}</p>
            </div>

            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("MINUTES")}</p>
            </div>
            <p style={{ marginBottom: "25px" }}>:</p>
            <div>
              <input
                type="number"
                onChange={handleInputChangeForMinSecs}
                min={0}
                max={99}
              ></input>
              <p className={styles.personalBestTimeLabel}>{t("SECONDS")}</p>
            </div>
          </div>
        </Col>
      </Row>

      <div className={styles.devider}></div>

      <div className={styles.DetailHead} style={{ marginBottom: "15px" }}>
        <p>{t("TRAINING")} </p>
        <h1>{t("GROUPS_")}</h1>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            A {t("SCHOOL")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            B {t("SCHOOL")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            C {t("SCHOOL")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            D {t("SCHOOL")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("ECOMRADES_RUNNERS")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("SOMETHING_ELSE")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("TRIATHLON_GRP")}
          </label>
        </div>
      </div>

      <div className="grpCheck">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" for="flexCheckDefault">
            {t("FREESTYLERS")}
          </label>
        </div>
      </div>

      <div className={styles.devider}></div>

      <div className={styles.DetailHead} style={{ marginBottom: "15px" }}>
        <p>{t("MEMBERSHIP")} </p>
        <h1>{t("INFO")}</h1>
      </div>

      <Form.Group className={styles.DetailInput}>
        <Form.Label>{t("MEMBERSHIP_VALID_UPTO")}</Form.Label>
        <Form.Control type="date" />
      </Form.Group>

      <div className={styles.devider}></div>

      <div className={styles.switchCase}>
        <div className={styles.switchCaseInfo}>
          <h1>{t("ACTIVE_MEMBERSHIP")}</h1>
          <p>{t("TURN_MEMBER_ON_OFF_AS_REQUIRED")}</p>
        </div>

        <Form.Check // prettier-ignore
          type="switch"
          id="custom-switch"
          className={styles.switchButton}
        />
      </div>

      <div className={styles.devider}></div>

      <div className={styles.switchCase}>
        <div className={styles.switchCaseInfo}>
          <h1>{t("CAN_VIEW_OTHER_CLUB_MEMBER")}</h1>
          <p>{t("ALLOW_USER_TO_SEE_OTHER_MEMBER")}</p>
        </div>

        <Form.Check
          type="switch"
          id="custom-switch"
          className={styles.switchButton}
        />
      </div>

      <div className={styles.devider}></div>

      <div className={styles.cancelOrSave}>
        <button className={styles.cancelBtn}>{t("CENCEL")}</button>
        <button
          className={styles.saveBtn}
          onClick={() => navigate("/members/member-detail")}
        >
          {t("SAVE_CHANGES")}
        </button>
      </div>
    </div>
  );
}

export default AddDetail;
