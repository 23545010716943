import React, { useEffect, useState } from "react";
import styles from "./AddGroup.module.css";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiDeleteBin6Line } from "react-icons/ri";
import DeleteResult from "../Members/MemberDetail/DeleteResult";

import { useForm } from "react-hook-form";
import { ValidationSchema } from "../../Utils/ValidationSchema";
import TrainzaApi from "../../Helpers/Api";
import { STATUS_CODE } from "../../Utils/StatusCode";
import { STATUS_MSG } from "../../Utils/StatusMsg";
import Toster from "../../Common/Toster/Toster";
import { userLogoutClear } from "../../redux/slices/userSlice";
import Loader from "../../Common/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
function EditGroup({
  predefaultData,
  setEditIndex,
  handleGetGroupList,
  handleDeleteGroup,
  show,
  handleClose,
  grpList,
  setGrpList,
}) {
  const { register, handleSubmit, formState, setValue, reset } = useForm({
    mode: "onChange",
  });

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.userToken);

  const [isLoading, setIsLoading] = useState(false);

  const [grpId, setGrpId] = useState();
  const [totalMember, setTotalMember] = useState();

  useEffect(() => {
    setGrpId(predefaultData?.id);
    setValue("GROUP_NAME", predefaultData?.name);
    setValue("SUBTITLE", predefaultData?.subtitle);

    setValue("ABOUT_INFO", predefaultData?.aboutInfo);
    setTotalMember(predefaultData?.totalMembers);
  }, [predefaultData]);

  function OnSubmit(data) {
    const updatedObject = {
      id: grpId,
      name: data.GROUP_NAME,
      subtitle: data.SUBTITLE,
      aboutInfo: data.ABOUT_INFO,
      totalMembers: totalMember,
    };

    const PostData = new FormData();

    PostData.append("groupId", grpId);
    PostData.append("name", data.GROUP_NAME);
    PostData.append("subtitle", data.SUBTITLE);
    PostData.append("aboutInfo", data.ABOUT_INFO);

    setIsLoading(true);
    TrainzaApi.addGroup(isAuthenticated, PostData).then((response) => {
      setIsLoading(false);

      const ResponseCode = STATUS_MSG[response?.data?.code];

      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
      } else {
        if (response?.code === STATUS_CODE.SUCCESS) {
          Toster(t("GROUP_UPDATED_SUCCESSFULLY"), "success");
          setEditIndex();

          const updatedData = grpList.map((item) => {
            if (item.id === grpId) {
              return updatedObject;
            }
            return item;
          });

          setGrpList(updatedData);
          reset();
        } else if (response?.data?.code == STATUS_CODE.VALIDATION_ERROR) {
          Toster(response?.data?.message, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  }

  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.MainBox}>
        <div className={styles.headingBox}>
          <p>
            {t("EDIT")}
            <span> {t("TRAINING_GRP")}</span>
          </p>
        </div>
        <Form onSubmit={handleSubmit(OnSubmit)}>
          <div className={styles.inputArea}>
            <Form.Group className={styles.inputText}>
              <Form.Label>{t("GRP_NAME")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("INPUT_GRP_NAME")}
                {...register("GROUP_NAME", ValidationSchema.GROUP_NAME)}
                isInvalid={!!formState.errors.GROUP_NAME}
              />

              <Form.Control.Feedback type="invalid">
                {t(formState.errors.GROUP_NAME?.message)}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className={styles.inputText}>
              <Form.Label>{t("SUBTITLE_GRP_TRAINER_OPTIONAL")}</Form.Label>
              <Form.Control
                type="text"
                {...register("SUBTITLE")}
                placeholder={t("INPUT_SUBTITLE")}
              />
            </Form.Group>

            <Form.Group className={styles.inputText}>
              <Form.Label>{t("ABOUT_INFO")}</Form.Label>
              <Form.Control
                as="textarea"
                placeholder={t("OPTONAL_DESCRIPTION")}
                {...register("ABOUT_INFO", ValidationSchema.ABOUT_INFO)}
                isInvalid={!!formState.errors.ABOUT_INFO}
              />
              <Form.Control.Feedback type="invalid">
                {t(formState.errors.ABOUT_INFO?.message)}
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className={styles.btnArea}>
            <div className={styles.cancelAndDeleteBtn}>
              <button
                className={styles.cancelBtn}
                type="button"
                onClick={() => {
                  setEditIndex();
                }}
              >
                {t("CENCEL")}
              </button>

              <button
                className={styles.cancelBtn}
                onClick={() => {
                  handleClose();
                }}
                type="button"
              >
                <RiDeleteBin6Line size="20" />
                {t("DELETE")}
              </button>
            </div>

            <button className={styles.addBtn} type="submit">
              <p>
                {t("SAVE")} <span>{t("GROUP")}</span>
              </p>
            </button>
          </div>
        </Form>
      </div>
      <DeleteResult
        show={show}
        handleClose={handleClose}
        handleDeleteGroup={handleDeleteGroup}
      />
    </div>
  );
}

export default EditGroup;
