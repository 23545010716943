import React, { useState } from "react";
import styles from "./AddWorkout.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdTime } from "react-icons/io";
import Form from "react-bootstrap/Form";
import { FiCalendar } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { FaPlus } from "react-icons/fa";
import SelectImg from "../../../Assests/Images/SelectImg.png";

function TrainingDateTime() {
  const { t } = useTranslation();
  const [startTime, setStartTime] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  return (
    <div>
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>1</p>
        </div>
        <h1>
          {t("TRAING_DATE_TIME_AND_GRP")}
          <span>({t("REQUIRED")})</span>
        </h1>
      </div>

      <div className={styles.dateTimeGroupBox}>
        <p>
          <span>{t("DATE_AND_TIME")} -</span> {t("SET_TRAINING_DATE_AND_TIME")}
        </p>

        <div className={styles.selectDateAndTime}>
          <div className={styles.datePickerBox}>
            <DatePicker
              showIcon
              toggleCalendarOnIconClick
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              className={styles.datePicker}
            />
            <FiCalendar color="#000000" size="20" />
          </div>

          <div className={styles.timePickerBox}>
            <DatePicker
              selected={startTime}
              onChange={(time) => setStartTime(time)}
              placeholderText={t("SELECT_TIME")}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              dateFormat="h:mm aa"
              className={styles.timePicker}
            />
            <IoMdTime color="#000000" size="25" />
          </div>

          <Form.Check
            className={styles.checkBoxArea}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={t("ANYTIME")}
          />
        </div>

        <div className={styles.devider}></div>

        <div className={styles.selectTraingBox}>
          <p>
            {t("SELECT")} <span>{t("TRAINING_GROUP")}</span>
          </p>

          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`A School`}
          />
          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`B School`}
          />
          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`C School`}
          />
          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`D School`}
          />
          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`Fledgelings
            `}
          />
          <Form.Check
            className={styles.checboxTraining}
            type={"checkbox"}
            id={`default-${"checkbox"}`}
            label={`Gomrades Build Up`}
          />
        </div>
      </div>

      <div className={styles.saveBox}>
        <button disabled={1}>
          <p>
            {t("SAVE")} <span>{t("ALL")}</span>
          </p>
        </button>
      </div>
    </div>
  );
}

export default TrainingDateTime;
