import React, { useEffect, useState } from "react";
import styles from "./Subscription.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userLogoutClear } from "../../../redux/slices/userSlice";
import { STATUS_CODE } from "../../../Utils/StatusCode";
import Toster from "../../../Common/Toster/Toster";
import TrainzaApi from "../../../Helpers/Api";
import Loader from "../../../Common/Loader/Loader";
import { capitalizeFirstLetter } from "../../../Utils/CommonFunction";
function Subscription() {
  const { t } = useTranslation();
  const isAuthenticated = useSelector((state) => state.user.userToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [activeMember, setActiveMember] = useState("");
  const [userName, setUserName] = useState("");

  const getBrandDetail = () => {
    const response = TrainzaApi.brandDetail(isAuthenticated);
    response.then((response) => {
      setIsLoading(false);
      if (
        response?.data?.code === STATUS_CODE.INVALID_TOKEN ||
        response?.data?.code === STATUS_CODE.SESSION_EXPIRED
      ) {
        dispatch(userLogoutClear());
        Toster(t("SESSION_EXPIRED"), "error");
        navigate("/sign-up");
      } else {
        const brandInfo = response?.data?.subscription;
        setActiveMember(brandInfo?.activeMembership);
        setUserName(response?.data?.brandInfo?.brandName);
      }
    });
  };

  useEffect(() => {
    getBrandDetail();
  }, []);
  return (
    <div>
      {isLoading && <Loader />}
      <div className={styles.brandHead}>
        <p>{capitalizeFirstLetter(userName)}</p>
      </div>
      <div className={styles.devider}></div>
      <div className={styles.currentSubscripton}>
        <p>CURRENT SUBSCRIPTION</p>
      </div>
      <div className={styles.devider}></div>
      <div className={styles.memberCount}>
        <p>{activeMember} Members</p>
      </div>
      <div className={styles.upgrade}>
        <p>Upgrade, Downgrade or Cancel Club Subscription</p>
      </div>
      <div className={styles.manageSubscription}>
        <p>To manage your subscription, please visit our website.</p>
      </div>
      <button
        className={styles.manageButton}
        onClick={() => window.open("http://www.trainza.co.za/")}
      >
        <p>
          MANAGE <span>SUBSCRIPTION</span>
        </p>
      </button>
    </div>
  );
}

export default Subscription;
