import React, { useState } from "react";
import styles from "./AddWorkout.module.css";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";
import { FaPlus } from "react-icons/fa";
import SelectImg from "../../../Assests/Images/SelectImg.png";
import ReactQuill from "react-quill";
import { MdOutlinePlayArrow } from "react-icons/md";
import { FaMountainSun } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { RiRouteLine } from "react-icons/ri";
import { CiRoute } from "react-icons/ci";

function RouteOptional() {
  const { t } = useTranslation();

  const [addManually, setAddManually] = useState(false);
  const [getDetail, setGetDetail] = useState(false);
  const [content, setContent] = useState("");
  const [isKm, setIsKm] = useState(true);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "color",
  ];

  const modules = {
    toolbar: [
      ["bold", "italic"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return (
    <div>
      <div className={styles.workoutHeader}>
        <div className={styles.partName}>
          <p>3</p>
        </div>
        <h1>
          ROUTE
          <span>(OPTIONAL)</span>
        </h1>
      </div>

      {/* WITHOUT ADD MANUALLY */}
      {!addManually && !getDetail && (
        <div className={styles.savedWorkoutBox}>
          <p>
            {t("SAVED")}
            <span>{t("ROUTE")}</span>
          </p>

          <div className={styles.selectWorkout}>
            <Form.Select aria-label="Default select example">
              <option>
                <p>
                  {t("SELECT")}
                  <span>{t("SAVED_ROUTE")}</span>
                </p>
              </option>
              <option value="1">
                <p>one</p>
              </option>
              <option value="2">
                <p>two</p>
              </option>
              <option value="3">
                <p>three</p>
              </option>
            </Form.Select>

            <div
              className={styles.addBtnBox}
              onClick={() => {
                setGetDetail(true);
              }}
            >
              <div className={styles.iconHolder}>
                <FaPlus color="#ff4300" />
              </div>
            </div>

            <img src={SelectImg} alt="SelectImg"></img>
          </div>

          <div className={styles.workoutNoteThat}>
            <p>
              Quickly add in a <span>saved route above,</span> or{" "}
              <span>add manually below</span>
            </p>
          </div>

          <button
            onClick={() => {
              setAddManually(true);
            }}
          >
            <p>
              {t("ADD")} <span>{t("MANUALLY")}</span>
            </p>
          </button>
        </div>
      )}

      {/* ADD WORKOUT MANUALLY */}
      {addManually && (
        <div>
          <div className={styles.savedWorkoutBoxManually}>
            <div className={styles.addManually}>
              <p>
                {t("ROUTE")} <span>{t("NAME")}</span>
              </p>

              <div className={styles.selectWorkoutManually}>
                <input placeholder={t("NAME_OF_ROUTE")}></input>
              </div>

              <p>
                {t("WORKOUT")}
                <span> {t("DETAILS")}</span> ({t("OPTIONAL_ROUTE_INFO")})
              </p>

              <ReactQuill
                theme="snow"
                value={content}
                onChange={handleEditorChange}
                modules={modules}
                formats={formats}
                placeholder={t("DETAILS_OF_YOUR_WORKOUT")}
                className="WorkoutTextEditor"
              />

              <div className={styles.videoLabel}>
                <div
                  className={styles.videoIcon}
                  style={{ transform: "rotate(-90deg)" }}
                >
                  <RiRouteLine color="#ffffff" />
                </div>
                <h1>
                  <span>{t("ROUTE")} </span>
                  {t("LINK")}
                  <span> ({t("OPTIONAL_")})</span>
                </h1>
              </div>

              <div className={styles.videoNote}>
                <p>{t("ADD_OPTIONAL_BUTTON")}</p>
              </div>

              <div className={styles.addLink}>
                <p>
                  <span>{t("LINK_TO_ROUTE")} </span>({t("STRAVE_MAPBOX")})
                </p>
                <input placeholder={t("ADD_LINK")}></input>
              </div>

              <div className={styles.videoLabel}>
                <div className={styles.videoIcon}>
                  <FaMountainSun color="#ffffff" />
                </div>
                <h1>
                  <span>{t("ROUTE")} </span>
                  {t("INFO")}
                  <span>({t("OPTIONAL_")})</span>
                </h1>
              </div>

              <div className={styles.videoNote}>
                <p>{t("ADD_OPTIONAL_ROUTE")}</p>
              </div>

              <div className={styles.uploadImage}>
                <button>
                  <p>
                    {t("UPLOAD")} <span>{t("IMAGE")}</span>
                  </p>
                </button>
              </div>
            </div>

            <div className={styles.devider}></div>

            <div className={styles.addLink}>
              <p>
                {t("ROUTE")} <span>{t("DISTANCE")} </span>({t("REQUIRED_")})
              </p>
            </div>

            <div className={styles.videoNote}>
              <p>{t("ADD_DISTANCE_INDICATION")}</p>
            </div>

            <div className={styles.devider}></div>

            <div className={styles.distanceInputBox}>
              {" "}
              <input className={styles.km1}></input>
              <p>.</p>
              <input className={styles.km2}></input>
              <p>{t("KM")}</p>
              <button
                style={{
                  background: isKm ? "#ff4300" : "#ffffff",
                  color: isKm ? "#FFFFFF" : "#000000",
                }}
                className={styles.chooseKm}
                onClick={() => {
                  setIsKm(true);
                }}
              >
                {t("KILOMATERS")} ({t("KM")})
              </button>
              <button
                style={{
                  background: isKm ? "#ffffff" : "#ff4300",
                  color: isKm ? "#000000" : "#FFFFFF",
                }}
                className={styles.chooseMile}
                onClick={() => {
                  setIsKm(false);
                }}
              >
                {t("MILES_MI")}
              </button>
            </div>
          </div>{" "}
          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                onClick={() => {
                  setAddManually(false);
                }}
              >
                {t("REMOVE")}
              </button>
            </div>
            <button className={styles.saveWorkout}>
              <p>
                {t("SAVE_")} <span>{t("ROUTE")}</span>
              </p>
            </button>
          </div>
        </div>
      )}

      {getDetail && (
        <div>
          <div className={styles.getDetailBox}>
            <p>Riverside Loop with Hill Repeats</p>
            <h1>23.15KM</h1>

            <div className={styles.devider}></div>

            <div className={styles.topic}>
              <p>
                Start Point: <span>Riverside Park Entrance</span>
              </p>
            </div>

            <div className={styles.description}>
              <p>
                Start Point: Riverside Park Entrance Start at the entrance of
                Riverside Park. Head east on the paved path alongside the river.
                After 0.5 miles, you'll reach a fork. Take the left path,
                continuing along the river.
              </p>

              <p>
                Run straight for another 0.8 miles until you reach the
                footbridge. Cross the footbridge, staying on the path as it
                curves to the right. Continue on the path for 1 mile until you
                reach the wooden boardwalk.
              </p>

              <p>
                Turn left onto the boardwalk and follow it along the marshland.
                After 0.3 miles, the boardwalk ends. Turn right onto the gravel
                path. Follow the gravel path for 0.7 miles until you reach the
                intersection. Turn left at the intersection and head south
              </p>

              <p>
                Continue straight for 0.6 miles until you reach the playground.
                Pass the playground on your right and continue straight onto the
                paved path.
              </p>

              <p>
                Follow the path as it curves to the left and then to the right.
                After 0.4 miles, you'll reach the starting point at the
                Riverside Park Entrance.
              </p>

              <p>Distance: Approximately 4.3 miles</p>
            </div>

            <div className={styles.topic}>
              <p>
                Terrain: Mostly flat with some slight inclines, paved and gravel
                paths, with scenic views of the river and marshland.
              </p>
            </div>

            <div className={styles.devider}></div>

            <div className={styles.routeInfo}>
              <div className={styles.linkBox}>
                <div className={styles.linkBoxIcon}>
                  <CiRoute color="#ffffff" size="25" />
                </div>
                <p>{t("ROUTE_")}</p>
                <h1>{t("LINK_")}</h1>
              </div>

              <div className={styles.linkBox}>
                <div className={styles.linkBoxIcon}>
                  <FaMountainSun color="#ffffff" size="25" />
                </div>
                <p>{t("ROUTE_")}</p>
                <h1>{t("INFO_")}</h1>
              </div>
            </div>
          </div>
          <div className={styles.cancelDeleteSave}>
            <div className={styles.cancelSave}>
              <button
                onClick={() => {
                  setGetDetail(false);
                }}
              >
                {t("REMOVE")}
              </button>
            </div>
            <button className={styles.saveWorkout}>
              <p>
                {t("EDIT")} <span>{t("ROUTE")}</span>
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RouteOptional;
