import React, { useState } from "react";
import styles from "./Result.module.css";
import profileImg from "../../../Assests/Images/ProfileDemo.png";
import CrossImg from "../../../Assests/Images/Cross.png";
import Edit from "../../../Assests/Images/Edit.png";
import DeleteResult from "./DeleteResult";
import EditResult from "./EditResult";
import { useTranslation } from "react-i18next";

function Result() {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  function handleShowEdit(index) {
    if (index === editIndex) {
      setShowEdit(false);
      setEditIndex();
    } else {
      setShowEdit(true);
      setEditIndex(index);
    }
  }
  return (
    <div>
      <div className={styles.userName}>
        <img src={profileImg} alt="profileImg"></img>
        <p>Willem Van Ooosthuiz</p>
      </div>

      {Array.from({ length: 10 }, (_, index) => (
        <React.Fragment key={index}>
          <div
            className={styles.runDetailBox}
            style={{ backgroundColor: index === editIndex ? "#DAF8B5" : "" }}
          >
            <div>
              <p className={styles.marathonName}>EDENVALE MARATHON</p>
              <div className={styles.marathonTiming}>
                <p>
                  <span>SUB</span> 5 OCT 2024- 4:34p/km - <span>42</span>KM -{" "}
                  <span>35:32</span>
                </p>
              </div>
            </div>
            <div className={styles.runDetailBoxEdit}>
              <img
                src={Edit}
                alt="edit"
                onClick={() => {
                  handleShowEdit(index);
                }}
              ></img>
              <img
                src={CrossImg}
                alt="crossImg"
                onClick={handleShowDelete}
              ></img>
            </div>
          </div>
          {editIndex === index ? (
            <React.Fragment>
              {showEdit ? (
                <EditResult
                  setShowEdit={setShowEdit}
                  setEditIndex={setEditIndex}
                />
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
        </React.Fragment>
      ))}

      <div className={styles.bottomSection}>
        <button>
          <p>
            {t("ADD")} <span>{t("RESULTS__")}</span>
          </p>
        </button>
        <div className={styles.result}>
          <p>
            133 <span>{t("RESULTS_")}</span>
          </p>
        </div>

        <button>
          <p>
            Load <span>More</span>
          </p>
        </button>
      </div>

      <DeleteResult handleClose={handleCloseDelete} show={showDelete} />
    </div>
  );
}

export default Result;
